import * as Sentry from '@sentry/react'
import React, { Suspense } from 'react'

import LoadingWidget from '../widgets/LoadingWidget'
import ErrorScreen from './ErrorScreen'

interface Props {
  children: React.ReactNode
}

const AppErrorBoundary: React.FC<Props> = ({ children }) => (
  <Sentry.ErrorBoundary fallback={({ error }) => <ErrorScreen error={error} />}>
    <Suspense fallback={<LoadingWidget variant="black" />}>{children}</Suspense>
  </Sentry.ErrorBoundary>
)

export default AppErrorBoundary
