import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { useHomeRoute } from '../../hooks/useHomeRoute'

const InvalidOrExpiredCard: React.FC = () => {
  const homeRoute = useHomeRoute()

  return (
    <Card className="my-4">
      <Card.Body>
        <Card.Title>Invalid link</Card.Title>

        <p>This link is either invalid or has been revoked.</p>

        <Link to={homeRoute}>
          <Button variant="primary">Return to home</Button>
        </Link>
      </Card.Body>
    </Card>
  )
}

export default InvalidOrExpiredCard
