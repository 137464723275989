import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'

import { COMPANY_TYPE_CODE } from '../../lib/constants'
import { USER_TYPE, UserType } from '../../types/misc'
import {
  SessionMetadataCompany,
  SessionMetadataItem,
  SessionMetadataUser,
} from '../../types/responses/session-metadata'
import { RootState } from '../store'

export interface SessionState {
  isInitialized: boolean
  isAuthenticated: boolean
  metadata?: SessionMetadataItem
  isUpdatingContext: boolean
}

const INITIAL_STATE: SessionState = {
  isInitialized: false,
  isAuthenticated: false,
  isUpdatingContext: false,
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: INITIAL_STATE,
  reducers: {
    initialiseSessionSuccess: (
      state,
      action: PayloadAction<{
        metadata: SessionMetadataItem
      }>,
    ) => {
      const { metadata } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        metadata,
      }
    },

    initialiseSessionFail: () => {
      return {
        ...INITIAL_STATE,
        isInitialized: true,
      }
    },

    initialiseSessionError: () => {
      return {
        ...INITIAL_STATE,
        isInitialized: true,
      }
    },

    signOutSuccess: () => {
      return {
        ...INITIAL_STATE,
      }
    },

    // TODO: Should find a way to update the current user by invalidating the
    // React Query cache instead of updating the state directly. We want to
    // minimize client-side state management as much as possible.
    updateCurrentUser(
      state,
      action: PayloadAction<Partial<SessionMetadataUser>>,
    ) {
      if (!state.metadata?.currentUser) {
        return
      }

      state.metadata.currentUser = {
        ...state.metadata.currentUser,
        ...action.payload,
      }
    },

    // TODO remove this, when updateCurrentUser has been address
    updateCurrentUserType(state, action: PayloadAction<Partial<UserType>>) {
      if (!state.metadata?.currentUser) {
        return
      }

      state.metadata.currentUserType = action.payload
    },

    // TODO: Should find a way to update the current company by invalidating the
    // React Query cache instead of updating the state directly. We want to
    // minimize client-side state management as much as possible.
    updateCurrentCompany(
      state,
      action: PayloadAction<Partial<SessionMetadataCompany>>,
    ) {
      if (!state.metadata?.currentCompany) {
        return
      }

      state.metadata.currentCompany = {
        ...state.metadata.currentCompany,
        ...action.payload,
      }
    },

    completeCompanyRegistration(state) {
      if (state.metadata?.currentCompany) {
        state.metadata.currentCompany.isRegistrationComplete = true
      }
    },
    setIsUpdatingContext(state, action: PayloadAction<boolean>) {
      state.isUpdatingContext = action.payload
    },
  },
})

export const {
  initialiseSessionSuccess,
  initialiseSessionFail,
  initialiseSessionError,
  signOutSuccess,
  setIsUpdatingContext,
  completeCompanyRegistration,
  updateCurrentUser,
  updateCurrentUserType,
  updateCurrentCompany,
} = sessionSlice.actions

export const selectSession = (state: RootState) => state.session

export const selectIsInitialized = createSelector(
  selectSession,
  (session) => session.isInitialized,
)

export const selectIsUpdatingContext = createSelector(
  selectSession,
  (session) => session.isUpdatingContext,
)

export const selectSessionMetadata = createSelector(
  selectSession,
  (session) => session.metadata,
)

export const selectCurrentUser = createSelector(
  selectSessionMetadata,
  (metadata) => {
    return metadata?.currentUser
  },
)

export const selectCurrentUserOrFail = createSelector(
  selectSessionMetadata,
  (metadata) => {
    const currentUser = metadata?.currentUser

    invariant(currentUser, 'Expected currentUser to be defined')

    return currentUser
  },
)

export const selectIsAuthenticated = createSelector(
  selectSession,
  (session) => session.isAuthenticated,
)

export const selectIsUnauthenticated = createSelector(
  selectSession,
  (session) => !session.isAuthenticated,
)

export const selectCompanies = createSelector(
  selectSessionMetadata,
  (metadata) => metadata?.companies || [],
)

export const selectPermissions = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser?.permissions || [],
)

export const selectIsIcaewUser = createSelector(
  selectPermissions,
  (permissions) => permissions.includes('icaew/view') || false,
)

export const selectUserTypes = createSelector(
  selectSessionMetadata,
  (metadata) => metadata?.userTypes || [],
)

export const selectHasClientAccount = createSelector(
  selectUserTypes,
  (scopes) => scopes.includes(USER_TYPE.client),
)

export const selectHasCompanyAccount = createSelector(
  selectUserTypes,
  selectCompanies,
  (scopes, companies) => {
    // TODO:
    // Shouldn't need to check companies.length. Only doing so because newly
    // registered users don't have their scopes set properly at the moment.
    return scopes.includes(USER_TYPE.company) || companies.length > 0
  },
)

export const selectCurrentCompany = createSelector(
  selectSessionMetadata,
  (metadata) => metadata?.currentCompany,
)

export const selectCurrentCompanyOrFail = createSelector(
  selectSessionMetadata,
  (metadata) => {
    const company = metadata?.currentCompany

    invariant(company, 'Expected currentCompany to be defined')

    return company
  },
)

export const selectCompanySponsor = createSelector(
  selectCurrentCompany,
  (currentCompany) => {
    if (!currentCompany?.referringCompanyName) {
      return
    }

    return {
      companyName: currentCompany.referringCompanyName,
      companyLogoUrl: currentCompany.referringCompanyLogoUrl,
    }
  },
)

export const selectIsRqCertifiedFirm = createSelector(
  selectCurrentCompany,
  (currentCompany) => !!currentCompany?.isRqRated,
)

export const selectRqCertifiedAssessment = createSelector(
  selectCurrentCompany,
  (currentCompany) => currentCompany?.rqCertifiedAssessment,
)

export const selectCurrentCompanyType = createSelector(
  selectCurrentCompany,
  (currentCompany) => currentCompany?.companyType,
)

export const selectIsIcaewRegulatedFirm = createSelector(
  selectCurrentCompany,
  (currentCompany) => currentCompany?.isRegulatedByIcaew === true,
)

export const selectCurrentUserType = createSelector(
  selectSessionMetadata,
  (metadata) => metadata?.currentUserType,
)

export const selectIsAuthenticatedClient = createSelector(
  selectCurrentUserType,
  (currentUserType) => currentUserType === USER_TYPE.client,
)

export const selectIsCompanyUser = createSelector(
  selectSession,
  (session) =>
    !!session.metadata?.currentCompany &&
    session.metadata.currentUserType === USER_TYPE.company,
)

export const selectIsGuestUser = createSelector(
  selectSession,
  (session) => session.isAuthenticated === false,
)

export const selectIsIfa = createSelector(
  selectCurrentCompany,
  (currentCompany) =>
    currentCompany?.companyType.code === COMPANY_TYPE_CODE.ifa,
)

export const sessionReducer = sessionSlice.reducer
