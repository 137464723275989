import classNames from 'classnames'
import React, { ImgHTMLAttributes } from 'react'

import rqCertifiedLogo from './rq-certified-logo.svg'

type Props = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>

const RqCertifiedLogo: React.FC<Props> = ({
  alt = 'RQ Certified',
  className,
  style,
  ...props
}) => (
  <img
    {...props}
    alt={alt}
    src={rqCertifiedLogo}
    className={classNames('img-fluid', className)}
    style={{ maxWidth: '100px', ...style }}
  />
)

export default RqCertifiedLogo
