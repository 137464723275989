import React from 'react'
import { Button } from 'react-bootstrap'

import { useOfficeContext } from '../../contexts/OfficeContext'
import { useAuth } from '../../hooks/useAuth'
import LoadingWidget from '../widgets/LoadingWidget'
import AccountDropdown from './OfficeLayout/components/AccountDropdown'

interface Props {
  children: React.ReactNode
}

const OfficeLayout: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, signOut } = useAuth()
  const office = useOfficeContext()

  if (!office.isInitialized) {
    return <LoadingWidget />
  }

  return (
    <div className="wrapper">
      <div className="mx-2 w-100">
        <div>
          {isAuthenticated && (
            <div className="d-flex justify-content-between mt-2 mb-3">
              <div className="me-2">
                <AccountDropdown />
              </div>
              <Button
                variant="link"
                onClick={() => signOut()}
                className="p-0 m-0"
              >
                Sign Out
              </Button>
            </div>
          )}
        </div>

        {children}
      </div>
    </div>
  )
}

export default OfficeLayout
