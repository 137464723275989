import React, { useState } from 'react'

import useSettingsState from '../hooks/useSettingsState'
import { SIDEBAR_BEHAVIOR, SIDEBAR_POSITION } from '../lib/constants'

/* eslint-disable unused-imports/no-unused-vars */
const initialState = {
  isOpen: true,
  /* eslint-disable @typescript-eslint/no-empty-function */
  setIsOpen: (open: boolean) => {},
  setPosition: (position: string) => {},
  setBehavior: (behavior: string) => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
  position: SIDEBAR_POSITION.LEFT,
  behavior: SIDEBAR_BEHAVIOR.STICKY,
}
/* eslint-enable unused-imports/no-unused-vars */

const SidebarContext = React.createContext(initialState)

interface Props {
  children: React.ReactNode
}

const SidebarProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [position, setPosition] = useSettingsState(
    'sidebarPosition',
    SIDEBAR_POSITION.LEFT,
  )
  const [behavior, setBehavior] = useSettingsState(
    'sidebarBehavior',
    SIDEBAR_BEHAVIOR.STICKY,
  )

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        position,
        setPosition,
        behavior,
        setBehavior,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export { SidebarContext, SidebarProvider }
