import { NormalReferralTypeOption, ReferralTypeOption } from '../../types/misc'
import { REFERRAL_TYPE_OPTION } from '../constants'

class ReferClientService {
  isNormalReferralType(
    referralTypeOption?: ReferralTypeOption,
  ): referralTypeOption is NormalReferralTypeOption {
    return (
      !!referralTypeOption &&
      referralTypeOption !== REFERRAL_TYPE_OPTION.bookCallForClient
    )
  }

  isBookCallForClientReferralType(
    referralTypeOption?: ReferralTypeOption,
  ): boolean {
    return (
      !!referralTypeOption &&
      referralTypeOption === REFERRAL_TYPE_OPTION.bookCallForClient
    )
  }
}

const referClientService = new ReferClientService()

export default referClientService
