import { SearchClientsOptions } from '../../components/widgets/ClientSelect'
import {
  CreateClientForReferralRequest,
  CreateClientPaymentRequest,
  CreateClientRequest,
  GetClientsParams,
  GetCompassClientReportsParams,
  RequestClientCompassUpdatesRequest,
  UpdateClientForReferralRequest,
  UpdateClientRequest,
  UpdateCompanyClientRequest,
} from '../../types/requests/clients'
import { ClientMetadataItem } from '../../types/responses/client-metadata'
import {
  ClientCollection,
  ClientItem,
  ClientPaymentItem,
  CreateOrUpdateClientViaCompassResponse,
  UpdateCompanyClientResponse,
} from '../../types/responses/clients'
import { CompassReportCollection } from '../../types/responses/compass-reports'
import apiService from './apiService'

class ClientService {
  private endpoint = '/v1/clients'

  async getClient(clientId: number): Promise<ClientItem> {
    const response = await apiService.get(`${this.endpoint}/${clientId}`)

    return response.data
  }

  async createClient(request: CreateClientRequest) {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  async createClientForReferral(request: CreateClientForReferralRequest) {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  async updateClientForReferral(
    clientId: ID,
    request: UpdateClientForReferralRequest,
  ) {
    const response = await apiService.patch(
      `${this.endpoint}/${clientId}`,
      request,
    )

    return response.data
  }

  async searchClients(
    options: SearchClientsOptions,
  ): Promise<ClientCollection> {
    const response = await apiService.get(this.endpoint, {
      params: {
        'company.id': options.companyId,
        'order[firstName]': 'asc',
        'order[lastName]': 'asc',
        fullName: options.clientName,
      },
    })

    return response.data
  }

  async getClients(params: GetClientsParams): Promise<ClientCollection> {
    const response = await apiService.get('/v1/clients', {
      params,
    })

    return response.data
  }

  async getClientCompassReports(
    params: GetCompassClientReportsParams,
  ): Promise<CompassReportCollection> {
    const response = await apiService.get('/v1/compass-reports', {
      params,
    })

    return response.data
  }

  async createClientMetadata(): Promise<ClientMetadataItem> {
    const response = await apiService.post('/v1/client-metadata', {})

    return response.data
  }

  async createOrUpdateClientViaCompass(
    request: UpdateCompanyClientRequest,
  ): Promise<CreateOrUpdateClientViaCompassResponse> {
    const response = await apiService.post(
      `${this.endpoint}/create-or-update-via-compass`,
      request,
    )

    return response.data
  }

  async updateCompanyClient(
    clientIri: IRI,
    request: UpdateCompanyClientRequest,
  ): Promise<UpdateCompanyClientResponse> {
    const response = await apiService.patch(clientIri, request)

    return response.data
  }

  async updateClient(
    clientIri: IRI,
    request: UpdateClientRequest,
  ): Promise<UpdateCompanyClientResponse> {
    const response = await apiService.patch(clientIri, request)

    return response.data
  }

  async delete(clientId: ID): Promise<unknown> {
    const response = await apiService.delete(`${this.endpoint}/${clientId}`)

    return response.data
  }

  async requestCompassUpdates(
    request: RequestClientCompassUpdatesRequest,
  ): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/request-updates`,
      request,
    )

    return response.data
  }

  async getClientPaymentByToken(
    clientId: number,
    token: string,
  ): Promise<ClientPaymentItem> {
    const response = await apiService.get<ClientPaymentItem>(
      `${this.endpoint}/${clientId}/payment`,
      { params: { token } },
    )

    return response.data
  }

  async submitPaymentDetailsforClient(
    clientId: number,
    token: string,
    request: CreateClientPaymentRequest,
  ): Promise<ClientPaymentItem> {
    const response = await apiService.patch<ClientPaymentItem>(
      `${this.endpoint}/${clientId}/payment`,
      request,
      { params: { token } },
    )

    return response.data
  }
}

const clientService = new ClientService()

export default clientService
