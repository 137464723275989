import React from 'react'
import { Link } from 'react-router-dom'

import useAppSelector from '../../../../../hooks/useAppSelector'
import { useRqCertifiedAssessmentOrFail } from '../../../../../hooks/useRqCertifiedAssessmentQuery'
import {
  RQ_CERTIFIED_ASSESSMENT_STAGE,
  RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE,
} from '../../../../../lib/constants'
import { isDevelopment } from '../../../../../lib/helpers/helperFunctions'
import { ROUTES } from '../../../../../lib/routes'
import {
  selectCurrentCompanyOrFail,
  selectIsRqCertifiedFirm,
} from '../../../../../redux/slices/session'
import RqCertifiedLogo from '../../../../misc/RqCertifiedLogo/RqCertifiedLogo'
import RqCertifiedStatusText from '../../../RqCertifiedLayout/components/RqCertifiedStatusText'
import IsRqCertifiedLogo from './IsRqCertifiedLogo'

const RqCertifiedSidebarWidget: React.FC = () => {
  const assessment = useRqCertifiedAssessmentOrFail!()
  const { currentStatus, assessmentOneStatus } = assessment
  const currentCompany = useAppSelector(selectCurrentCompanyOrFail)
  const isRqCertified = useAppSelector(selectIsRqCertifiedFirm)

  // Hide the widget if firm has failed the assessment
  if (assessment.currentStatus === RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.failed) {
    return null
  }

  function getCta(): string | null {
    // No need to show CTA if firm is already RQ Certified
    if (isRqCertified) {
      return null
    }

    if (currentStatus === RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.inProgress) {
      return 'Continue application'
    }

    // Firm just passed stage two and not yet started stage two
    if (
      assessment.currentStage === RQ_CERTIFIED_ASSESSMENT_STAGE.two &&
      assessment.currentStatus ===
        RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.notStarted
    ) {
      return `Take ${assessment.assessmentTwoName}`
    }

    return 'Learn more'
  }

  const cta = getCta()

  const isYetToStartAssessment =
    !isRqCertified &&
    assessmentOneStatus === RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.notStarted

  // We'll temporarily hide the widget if the firm has not yet started the assessment
  // https://rqratings.atlassian.net/jira/software/projects/RQ/boards/1?selectedIssue=RQ-916
  if (isYetToStartAssessment) {
    return null
  }

  return (
    <aside className="d-flex flex-column gap-3 align-items-center text-center px-3 pt-4">
      {isYetToStartAssessment && (
        <span className="text-black text-xl">
          Interested in becoming RQ Certified?
        </span>
      )}

      {!isRqCertified && (
        <RqCertifiedStatusText assessment={assessment} className="mb-1" />
      )}

      <Link to={ROUTES.rqCertifiedApplicationStatus}>
        {isRqCertified ? (
          <IsRqCertifiedLogo companyName={currentCompany.presentationName} />
        ) : (
          <RqCertifiedLogo
            title={
              isDevelopment() ? `Assessment ID: ${assessment.id}` : undefined
            }
          />
        )}
      </Link>

      {cta && (
        <Link
          to={ROUTES.rqCertifiedApplicationStatus}
          className="btn btn-outline-primary px-4"
        >
          {cta}
        </Link>
      )}
    </aside>
  )
}

export default RqCertifiedSidebarWidget
