import React from 'react'
import { useRouteError } from 'react-router'

import ErrorScreen from './ErrorScreen'

const RootError: React.FC = () => {
  const error = useRouteError()

  return <ErrorScreen error={error} />
}

export default RootError
