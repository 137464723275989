import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

import ErrorMessage from '../../../misc/ErrorMessage'

const ErrorFallback: React.FC = () => (
  <Container>
    <Row>
      <Col lg={8}>
        <Card>
          <Card.Body>
            <ErrorMessage />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
)

export default ErrorFallback
