import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import RqCertifiedLogo from '../../../../misc/RqCertifiedLogo/RqCertifiedLogo'

interface Props {
  companyName: string
}

const IsRqCertifiedLogo: React.FC<Props> = ({ companyName }) => (
  <OverlayTrigger
    placement="right"
    overlay={
      <Tooltip className="tooltip-lg">
        <>
          <strong>{companyName}</strong> is RQ Certified
        </>
      </Tooltip>
    }
  >
    {/* We need this wrapper div for the tooltip */}
    <div className="d-inline-block">
      <RqCertifiedLogo />
    </div>
  </OverlayTrigger>
)

export default IsRqCertifiedLogo
