export function getApiUrl() {
  const hostUrl = `${window.location.protocol}//${window.location.host}`

  if (hostUrl.includes('frontend-feature')) {
    return process.env.REACT_APP_RQ_API_FEATURE_URL
  }

  if (hostUrl.includes('frontend-hotfix')) {
    return process.env.REACT_APP_RQ_API_HOTFIX_URL
  }

  if (hostUrl.includes('host.docker.internal')) {
    return process.env.REACT_APP_RQ_API_PRIVATE_URL
  }

  return process.env.REACT_APP_RQ_API_URL
}
