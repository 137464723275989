import classNames from 'classnames'
import React, { MouseEventHandler } from 'react'
import { Badge, Collapse } from 'react-bootstrap'
import { Link, matchPath, useLocation } from 'react-router-dom'

import useSidebar from '../../../../../hooks/useSidebar'
import { matchesPaths } from '../../../../../lib/helpers/routeHelpers'
import { ROUTES } from '../../../../../lib/routes'
import { CLIENT_DASHBOARD_SIDEBAR_LINKS } from '../../ClientDashboardLayout'
import { DASHBOARD_SIDEBAR_LINKS } from '../../CompanyDashboardLayout'

interface Props {
  className?: string
  depth: number
  href: string
  icon: React.ReactElement
  badge?: string
  open?: boolean
  title: string
  children?: React.ReactNode
  onClick?: MouseEventHandler
  linkClassName?: string
}

const SidebarNavListItem: React.FC<Props> = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
    onClick,
    className,
    linkClassName,
  } = props

  const { pathname: currentRoute } = useLocation()
  const [open, setOpen] = React.useState(openProp)
  const { setIsOpen: setSidebarIsOpen } = useSidebar()

  const handleToggle: MouseEventHandler = (event) => {
    if (onClick) {
      onClick(event)
    }

    setOpen((state) => !state)
  }

  if (children) {
    return (
      <li
        className={classNames(
          `sidebar-item ${open ? 'active' : ''}`,
          className,
        )}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={classNames(
            `sidebar-link ${open ? '' : 'collapsed'}`,
            linkClassName,
          )}
          data-bs-toggle="collapse"
          aria-expanded={open ? 'true' : 'false'}
          data-depth={depth}
          onClick={handleToggle}
        >
          {Icon}
          <span className="align-middle" data-depth={depth}>
            {title}
          </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="">
              {badge}
            </Badge>
          )}
          {open ? <div /> : <div />}
        </a>

        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    )
  }

  function isSidebarLinkActive(href: string) {
    switch (title) {
      case DASHBOARD_SIDEBAR_LINKS.compass:
        return matchesPaths(currentRoute, [
          ROUTES.companyCompassDashboard,
          ROUTES.companyCompassSnapshots,
          ROUTES.companyCompassGlobalLink,
        ])

      case DASHBOARD_SIDEBAR_LINKS.contacts:
        return matchesPaths(currentRoute, [
          ROUTES.contacts,
          ROUTES.importContacts,
        ])

      case DASHBOARD_SIDEBAR_LINKS.referrals:
        return matchesPaths(currentRoute, [
          ROUTES.referralsDashboard,
          ROUTES.referrals,
          ROUTES.referralsImport,
          ROUTES.referralSummary,
        ])

      case DASHBOARD_SIDEBAR_LINKS.referYourClient:
        return matchesPaths(currentRoute, [ROUTES.referClient])

      case CLIENT_DASHBOARD_SIDEBAR_LINKS.yourSnapshots:
        return matchesPaths(currentRoute, [
          ROUTES.compassSnapshots,
          ROUTES.compassStart,
        ])

      case DASHBOARD_SIDEBAR_LINKS.yourRelationships:
        return matchesPaths(currentRoute, [
          ROUTES.referralPartners,
          ROUTES.referralPartnersApprovals,
          ROUTES.relationshipStats,
          ROUTES.inviteToRq,
          ROUTES.addRelationship,
        ])

      default:
        return !!matchPath(currentRoute, href)
    }
  }

  return (
    <li className={classNames('sidebar-item', className)}>
      <Link
        data-depth={depth}
        to={href}
        className={classNames(
          'sidebar-link d-flex align-items-center justify-content-between gap-2',
          { active: isSidebarLinkActive(href) },
          linkClassName,
        )}
        onClick={(event) => {
          setSidebarIsOpen(true)

          if (onClick) {
            onClick(event)
          }
        }}
      >
        <div>
          {Icon}
          <span>{title}</span>
        </div>
        {badge && <Badge>{badge}</Badge>}
      </Link>
    </li>
  )
}

export default SidebarNavListItem
