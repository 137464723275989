import React from 'react'

import Main from '../../misc/Main'

interface Props {
  children: React.ReactNode
}

const LandingWhiteLayout: React.FC<Props> = ({ children }) => (
  <div className="bg-white">
    <Main>{children}</Main>
  </div>
)

export default LandingWhiteLayout
