import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { Tooltip } from 'react-bootstrap'
import { OverlayTrigger } from 'react-bootstrap'
import { Info } from 'react-feather'

export interface RadioOptionsProps<TValue> {
  options: {
    label: string
    value: TValue
    tooltip?: string | React.ReactNode
  }[]
  value?: TValue
  onChange?: (value: TValue) => void
  optionLabelClassName?: string
  optionLabelStyle?: CSSProperties
  inputName?: string
  isDisabled?: boolean
}

export type RadioOptionsType =
  | string
  | number
  | readonly string[]
  | undefined
  | boolean

function RadioOptionsInput<TValue extends RadioOptionsType>({
  options,
  onChange,
  value,
  optionLabelClassName,
  optionLabelStyle,
  inputName,
  isDisabled,
}: RadioOptionsProps<TValue>) {
  function handleChange(value: TValue) {
    if (onChange) {
      onChange(value)
    }
  }

  function renderOptionLabel(
    option: RadioOptionsProps<TValue>['options'][number],
  ) {
    if (option.tooltip) {
      return (
        <div className="d-flex align-items-center">
          <div className="me-1">{option.label}</div>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{option.tooltip}</Tooltip>}
          >
            <Info className="text-primary ms-1" size={15} />
          </OverlayTrigger>
        </div>
      )
    }

    return <div>{option.label}</div>
  }

  return (
    <>
      <div className="d-flex flex-wrap" style={{ gap: '10px' }}>
        {options.map((option) => {
          const isSelected = option.value === value
          const id = `field_${option.value}`

          return (
            <label
              key={option.label}
              className={classNames(
                'd-flex py-2 px-3 border rounded cursor-pointer align-items-center',
                isSelected && !isDisabled
                  ? 'border-primary'
                  : 'border-gray-400',
                isDisabled && 'input-disabled',
                optionLabelClassName,
              )}
              style={optionLabelStyle}
            >
              <input
                id={id}
                name={inputName}
                checked={isSelected}
                type="radio"
                value={
                  typeof option.value === 'boolean'
                    ? option.value.toString()
                    : option.value
                }
                className="me-2"
                onChange={() => handleChange(option.value)}
                disabled={isDisabled}
              />

              {renderOptionLabel(option)}
            </label>
          )
        })}
      </div>
    </>
  )
}

export default RadioOptionsInput
