import { isOffice } from '../lib/helpers/officeHelpers'
import { ROUTES } from '../lib/routes'
import { selectIsOnboardingChecklistIncomplete } from '../redux/slices/onboardingChecklist'
import {
  selectIsAuthenticatedClient,
  selectIsCompanyUser,
  selectIsIcaewUser,
  selectIsIfa,
} from '../redux/slices/session'
import useAppSelector from './useAppSelector'

export function useHomeRoute() {
  const isAuthenticatedClient = useAppSelector(selectIsAuthenticatedClient)
  const isCompanyUser = useAppSelector(selectIsCompanyUser)
  const isIcaewUser = useAppSelector(selectIsIcaewUser)
  const isIfa = useAppSelector(selectIsIfa)
  const isOnboardingChecklistIncomplete = useAppSelector(
    selectIsOnboardingChecklistIncomplete,
  )

  if (isOffice()) {
    return ROUTES.officeTaskPane
  }

  if (isAuthenticatedClient) {
    return ROUTES.compassDashboard
  }

  if (isIcaewUser) {
    return ROUTES.icaewOverview
  }

  if (isCompanyUser) {
    if (isOnboardingChecklistIncomplete) {
      return ROUTES.gettingStarted
    }

    return isIfa ? ROUTES.referralPartners : ROUTES.companyCompassDashboard
  }

  // TODO:
  // This assumes that users can only register as company users.
  // Will need to account for client users registering too.
  return ROUTES.register
}
