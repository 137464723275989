import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useIsPdf } from '../../hooks/useIsPdf'
import { isProduction } from '../../lib/helpers/helperFunctions'
import RadioOptions from '../misc/RadioOptions'

export type ChartType = 'normal' | 'stacked'

export const CHART_TYPE: Record<ChartType, ChartType> = {
  normal: 'normal',
  stacked: 'stacked',
}

const SelectChartType: React.FC = () => {
  const isPdf = useIsPdf()
  const { chartType, setChartType } = useChartType()

  if (isProduction() || isPdf) {
    return null
  }

  return (
    <RadioOptions<ChartType>
      value={chartType}
      optionLabelClassName="text-sm"
      options={[
        { label: 'Normal bar chart', value: CHART_TYPE.normal },
        { label: 'Stacked bar chart', value: CHART_TYPE.stacked },
      ]}
      onChange={(newChartType) => setChartType(newChartType)}
    />
  )
}

export function useChartType() {
  const [searchParams, setSearchParams] = useSearchParams()

  const chartType = (searchParams.get('chartType') ||
    CHART_TYPE.normal) as ChartType

  function setChartType(newChartType: ChartType) {
    searchParams.set('chartType', newChartType)
    setSearchParams(searchParams)
  }

  return {
    chartType,
    setChartType,
  }
}

export default SelectChartType
