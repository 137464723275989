import classNames from 'classnames'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import companyIconUrl from '../../assets/img/icons/company.png'
import { COMPANY_TYPE_CODE } from '../../lib/constants'
import { truncateText } from '../../lib/helpers/helperFunctions'
import { CompanyTypeCode } from '../../types/misc'
import CompanyTypeIcon from './CompanyIcon/CompanyTypeIcon'
import Image from './Image'

export interface CompanyLogoOrNameProps {
  logoUrl?: string | null
  name: string
  companyTypeCode?: CompanyTypeCode // TODO: Make this required
  showLogoFallback?: boolean
  showNameFallback?: boolean
  wrapperProps?: React.HTMLProps<HTMLDivElement>
  fallbackProps?: React.HTMLProps<HTMLDivElement>
  imageStyle?: React.CSSProperties
  companyTypeIconStyle?: React.CSSProperties
  truncateName?: boolean
  truncateThreshold?: number
}

const CompanyLogoOrName: React.FC<CompanyLogoOrNameProps> = ({
  logoUrl,
  name,
  companyTypeCode = COMPANY_TYPE_CODE.other,
  showLogoFallback = true,
  showNameFallback = true,
  wrapperProps,
  fallbackProps,
  imageStyle,
  companyTypeIconStyle,
  truncateName = true,
  truncateThreshold = 60,
}) => {
  const companyIconAndNameElement = (
    <div
      {...fallbackProps}
      className={classNames(
        'd-flex align-items-center gap-2',
        fallbackProps?.className,
      )}
    >
      {showLogoFallback && (
        <CompanyTypeIcon
          companyTypeCode={companyTypeCode}
          src={companyIconUrl}
          style={{ maxHeight: '20px', ...companyTypeIconStyle }}
          alt={name}
          className="img-fluid"
        />
      )}

      {showNameFallback && (
        <div>
          {truncateName
            ? truncateText(name, { maxLength: truncateThreshold })
            : name}
        </div>
      )}
    </div>
  )

  return (
    <div {...wrapperProps}>
      {logoUrl ? (
        <OverlayTrigger overlay={<Tooltip>{name}</Tooltip>}>
          {/* Need the div wrapper for the tooltip to render properly */}
          <div>
            <Image
              src={logoUrl}
              fallback={companyIconAndNameElement}
              style={{
                maxHeight: '24px',
                maxWidth: '110px',
                width: '100%',
                ...imageStyle,
              }}
              alt={name}
              className="img-fluid"
            />
          </div>
        </OverlayTrigger>
      ) : (
        companyIconAndNameElement
      )}
    </div>
  )
}

export default CompanyLogoOrName
