import { AxiosError } from 'axios'
import { KeycloakTokenParsed } from 'keycloak-js'
import { SetRequired } from 'type-fest'

import { TooltipHint } from '../components/form/FieldLabel'

export type ConsentType = 'ICAEW'
export type ConsentDecisionType = 'OPT_IN' | 'OPT_OUT'

export type CompanyTypeCode =
  | IfaCompanyTypeCode
  | AccountantCompanyTypeCode
  | SolicitorCompanyTypeCode
  | MortgageAdviserCompanyTypeCode
  | InsuranceAdviserCompanyTypeCode
  | CorporateFinanceCompanyTypeCode
  | InvestmentManagementTypeCode
  | 'auto_enrolment'
  | 'business_coach'
  | 'conveyancing'
  | 'employee_benefits_provider'
  | 'estates_admin'
  | 'estate_planner'
  | 'estates_agent'
  | 'management_consultant'
  | 'marketing_consultant'
  | 'property_developer'
  | 'software_provider'
  | 'surveyor'
  | 'venture_capital'
  | 'other'

export type IfaCompanyTypeCode = 'ifa'
export type MortgageAdviserCompanyTypeCode = 'mortgage_adviser'
export type InsuranceAdviserCompanyTypeCode = 'insurance_adviser'
export type AccountantCompanyTypeCode = 'accountant'
export type SolicitorCompanyTypeCode = 'solicitor'
export type CorporateFinanceCompanyTypeCode = 'corporate_finance'
export type InvestmentManagementTypeCode = 'investment_management'

export type CommercialAgreementType = 'NO' | 'YFS' | 'YJV' | 'RQP'
export type CommercialAgreementDirectionType = 'FROM_TO' | 'TO_FROM'
export type CommercialAgreementReferralDirection = 'OUTGOING' | 'INCOMING'

export type CompassReportStatus = 'INVITED' | 'IN_PROGRESS' | 'COMPLETE'
export type CompassReportPriorityType = 'HIGH' | 'MEDIUM' | 'LOW'
export type CompassPdfReportSection = '1' | '2' | '3' | '4'

export type CompanyEmailType = 'COMPASS_INVITE'
export type CompanyIdentifierType = 'companyId' | 'frn'
export type CompanyStatus = 'SUSPENDED' | 'CLAIMED'

export interface FcaMonitoring {
  flag: boolean | null
  code: string
  message: string
  lastUpdated: string | null
}

export type JwtPayload = KeycloakTokenParsed & {
  rq?: {
    register_funnel?: 'company' | 'compass'
  }
}

export type ObjectMap<T> = Record<keyof T, keyof T>

export type PanelStatus = 'requested' | 'approved' | 'rejected'
export type PanelReferralStats = {
  fromCompanyId: number
  toCompanyId: number
  sent: number
  received: number
  incomeYTD: number
  incomeAllTime: number
}

export type PendingPanelAction =
  | 'respondToInvite'
  | 'reviewCommercialAgreement'
  | 'addCommercialAgreement'

export type ReviewType = 'written' | 'phone_interview'

export type ReferralApprovalStatus = 'pending' | 'approved' | 'rejected'

export type ReferralStatusCode =
  | 'awaiting_client_consent'
  | 'awaiting_client_meeting'
  | 'awaiting_client_schedule_meeting'
  | 'complete'
  | 'initial_meeting_held'
  | 'initial_meeting_scheduled'
  | 'live'
  | 'won_completed'
  | 'not_progressing'
  | 'unable_contact_client'
  | 'awaiting_client_response'

export type ReferralType = 'indirect' | 'direct'

export type ReferralTypeOption =
  | 'indirect'
  | 'direct'
  | 'directCc'
  | 'bookCallForClient'

export type ReferralNoteType =
  | 'referral_created'
  | 'referral_updated'
  | 'referral_imported'
  | 'referral_approved'
  | 'referral_rejected'
  | 'request_client_introduction'
  | 'request_client_fee_consent'
  | 'request_client_bank_details'
  | 'request_client_introduction_consent'
  | 'nothing_to_rebate'
  | 'previously_provided_bank_details'

export type NormalReferralTypeOption = 'indirect' | 'direct' | 'directCc'

export type ReferralCommmissionConsent = 'CONSENT' | 'DO_NOT_CONSENT'
export type ReferralCommmissionClientConsentStatus =
  | 'waiting_client_consent'
  | 'waiting_client_bank_details'
  | 'complete'

export type ReferralCommissionRqStatus =
  | 'rq_send_invoice'
  | 'rq_invoice_sent'
  | 'rq_invoice_paid'
  | 'complete'

export type RecommendedPartnerActionType =
  | 'DISMISS'
  | 'ACCEPT_WITH_TERMS'
  | 'ACCEPT_WITHOUT_TERMS'

export interface ReferralCommissionExpectedCommission {
  hasCommercialAgreement?: boolean
  lowerExpectedCommissionRange: number | null
  upperExpectedCommissionRange: number | null
  provisionalRetainAmount: number | null
  provisionalRebateAmount: number | null
}

export interface FcaService {
  category: string
  permissions: string[]
}

export type FeeSharingType = 'PERCENTAGE' | 'SET_AMOUNT'

export type FeePayBackType = 'percentage' | 'set_amount'

export type RqPartnershipType = 'percentage' | 'set_amount' | 'none'

export type RqCertifiedAssessmentStatus =
  | 'NOT_STARTED'
  | 'PENDING_PAYMENT_CONFIRMATION'
  | 'IN_PROGRESS'
  | 'IN_REVIEW'
  | 'PASSED'
  | 'FAILED'

export type RqCertifiedAssessmentStage = 'one' | 'two'

export enum MutationIconState {
  ok,
  mutating,
  success,
  error,
}

export interface MutationIconProps {
  state: MutationIconState
  message: string
}

export type FormErrors = Record<string, string>

export type TypedFormErrors<Values> = {
  [K in keyof Values]?: string
}

export interface SelectOption<TValue = string> {
  label: string
  value: TValue
  tooltipHint?: TooltipHint
}

export type StateValue = string | number | boolean | number[] | null

export type StateKey =
  | 'watched_demo_video'
  | 'interacted_with_rq_certified_announcement'
  | 'interacted_with_first_relationship_request_approved_modal'
  | 'client_reviewed_partners'
  | 'panel_approved_new'
  | 'panel_approved_updated'
  | 'panel_requested_new'
  | 'panel_requested_updated'
  | 'referral_from_new'
  | 'referral_from_updated'
  | 'referral_to_new'
  | 'referral_to_updated'

export type StateType = 'user' | 'company' | 'companyUser'

export const USER_TYPE = {
  client: 'CLIENT',
  company: 'COMPANY',
  icaew: 'ICAEW',
} satisfies Record<string, UserType>

export type UserType = 'ICAEW' | 'CLIENT' | 'COMPANY'

export type PermissionFilter = 'edit_compass' | 'view_compass'

export type FcaType =
  | 'appointed_representative'
  | 'directly_authorised'
  | 'trading_name'

export type DoughnutChartSize = 'sm' | 'md'

export type NonNullableAxiosError<T> = SetRequired<AxiosError<T>, 'response'>

export type EmptyObject = Record<string, never>
