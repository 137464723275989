import queryString from 'query-string'

import { CompanyProfileSearchParams } from '../../components/misc/CompanyProfile/CompanyProfile'
import {
  CommercialAgreementReferralDirection,
  CompassPdfReportSection,
} from '../../types/misc'
import { ROUTES } from '../routes'
import { RecommendedPartnerStep } from './recommendedPartnerService'

// TODO: Move all the URL helpers (e.g., buildReferClientUrl()) to this service
class RouteService {
  addRelationship(searchParams?: AddRelationshipSearchParams) {
    return `${ROUTES.addRelationship}?${queryString.stringify(
      searchParams ?? {},
    )}`
  }

  // TODO: Move panelId out of searchParams and as a separate parameter
  addRelationshipAgreement(
    panelId: number,
    searchParams: AddRelationshipAgreementSearchParams,
  ) {
    const queryStringParam = queryString.stringify(searchParams)

    return `${ROUTES.addRelationshipAgreement.replace(
      ':panelId',
      panelId.toString(),
    )}?${queryStringParam}`
  }

  companyProfile(searchParams: CompanyProfileSearchParams) {
    return `${ROUTES.companyProfile}?${queryString.stringify(searchParams)}`
  }

  compassPdfReportPreview(params: CompassPdfReportPreviewPageParams) {
    return `${ROUTES.compassPdfReportPreview}?${queryString.stringify(params)}`
  }

  compassPdfReportSection(
    section: CompassPdfReportSection,
    params: CompassPdfReportPreviewSearchParams,
  ) {
    return `${ROUTES.compassPdfReportSection.replace(':section', section)}?${queryString.stringify(params)}`
  }

  referralPartners(searchParams?: ReferralPartnersSearchParams) {
    const params = searchParams || {}

    if (!params.hasSentFirstInvite) {
      delete params.hasSentFirstInvite
    }

    return `${ROUTES.referralPartners}?${queryString.stringify(params)}`
  }
}

const routeService = new RouteService()

export interface AddRelationshipSearchParams {
  hasSentFirstInvite?: boolean
}

export interface AddRelationshipAgreementSearchParams {
  referralDirection?: CommercialAgreementReferralDirection
  returnPath?: string
}

export interface CompassPdfReportPreviewPageParams {
  token: string
  dateOfBirth?: string
}

export interface CompassPdfReportSearchParams {
  token: string
  dateOfBirth?: string
  browserlessKey?: string
}

export type CompassPdfReportPreviewSearchParams = CompassPdfReportSearchParams

export const COMPASS_PDF_REPORT_PREVIEW_SEARCH_PARAMS: Record<
  keyof CompassPdfReportSearchParams,
  keyof CompassPdfReportSearchParams
> = {
  token: 'token',
  dateOfBirth: 'dateOfBirth',
  browserlessKey: 'browserlessKey',
}

export const ADD_RELATIONSHIP_AGREEMENT_SEARCH_PARAMS: Record<
  keyof AddRelationshipAgreementSearchParams,
  keyof AddRelationshipAgreementSearchParams
> = {
  referralDirection: 'referralDirection',
  returnPath: 'returnPath',
}

export type ReferralPartnersSearchParams = {
  hasSentFirstInvite?: boolean
  companyName?: string
  identifier?: string
  identifierType?: string
  recommendedPartnerId?: string
  recommendedPartnerStep?: RecommendedPartnerStep
  tab?: string
}

export const REFERRAL_PARTNERS_SEARCH_PARAMS: Record<
  keyof ReferralPartnersSearchParams,
  keyof ReferralPartnersSearchParams
> = {
  hasSentFirstInvite: 'hasSentFirstInvite',
  companyName: 'companyName',
  identifier: 'identifier',
  identifierType: 'identifierType',
  recommendedPartnerId: 'recommendedPartnerId',
  recommendedPartnerStep: 'recommendedPartnerStep',
  tab: 'tab',
}

export default routeService
