import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
  dark?: boolean
}
const OverlayCard: React.FC<Props> = ({ children, className, dark }) => {
  const backgroundColor = dark
    ? 'color-mix(in srgb, black 70%, transparent)'
    : 'color-mix(in srgb, var(--bs-body-bg) 50%, transparent)'

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1000,
        top: '-1rem',
        left: '-1rem',
        width: 'calc(100% + 2rem)',
        height: 'calc(100% + 2rem)',
        padding: '1rem',
        backdropFilter: 'blur(5px)',
        backgroundColor: backgroundColor,
      }}
    >
      <div
        className={`bg-white shadow-lg rounded-lg mx-auto p-4 ${className}`}
        style={{ width: 'calc(100% - 2rem)', maxWidth: '700px' }}
      >
        {children}
      </div>
    </div>
  )
}

export default OverlayCard
