import './dumpStyles.scss'

import React, { useState } from 'react'

interface Props {
  title: string
  value: unknown
  expanded?: boolean
  darkMode?: boolean
}

// Use for debugging JSON-like data / React state
const Dump: React.FC<Props> = ({
  title,
  value,
  expanded = false,
  darkMode,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded)
  const toggleValues = () => setIsExpanded(!isExpanded)

  const syntaxHighlight = (json: string) => {
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
        let cls = 'number'
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key'
          } else {
            cls = 'string'
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean'
        } else if (/null/.test(match)) {
          cls = 'null'
        }
        return '<span class="' + cls + '">' + match + '</span>'
      },
    )
  }

  const renderDump = () => {
    if (typeof value === 'undefined' || null) {
      return <pre className="dump">{JSON.stringify(value, null, 2)}</pre>
    }

    return (
      <pre
        className="dump"
        dangerouslySetInnerHTML={{
          __html: syntaxHighlight(JSON.stringify(value, null, 2)),
        }}
      ></pre>
    )
  }

  return (
    <div
      style={{
        backgroundColor: darkMode ? 'black' : '#f5f5f5',
        border: darkMode ? '1px solid #989898' : 'none',
        fontSize: '12px',
        margin: '12px 0',
        padding: '6px',
      }}
    >
      <div
        style={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}
        onClick={toggleValues}
      >
        {title}
        <span
          style={{
            color: darkMode ? '#989898' : '#393939',
            fontSize: '8px',
            marginLeft: '7px',
          }}
        >
          {isExpanded ? '▲' : '▼'}
        </span>
      </div>

      {isExpanded && renderDump()}
    </div>
  )
}

export default Dump
