import classNames from 'classnames'
import React from 'react'

import useAppSelector from '../../../../hooks/useAppSelector'
import {
  RQ_CERTIFIED_ASSESSMENT_STAGE,
  RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE,
  THEME_PALETTE_LIGHT,
} from '../../../../lib/constants'
import { selectIsRqCertifiedFirm } from '../../../../redux/slices/session'
import { RqCertifiedAssessmentItem } from '../../../../types/responses/rq-certified-assessment'

interface Props {
  assessment: RqCertifiedAssessmentItem
  inline?: boolean
  className?: string
}

interface StatusConfig {
  color: string
  text: string
}

const RqCertifiedStatusText: React.FC<Props> = ({
  assessment,
  inline,
  className,
}) => {
  const isRqCertified = useAppSelector(selectIsRqCertifiedFirm)

  function getStatusConfig(): StatusConfig | null {
    // Handle special case: If we've just passed stage 1
    if (
      assessment.currentStage === RQ_CERTIFIED_ASSESSMENT_STAGE.two &&
      assessment.currentStatus ===
        RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.notStarted
    ) {
      return {
        text: `${assessment.assessmentOneName} Passed`,
        color: THEME_PALETTE_LIGHT.primary,
      }
    }

    if (isRqCertified && inline) {
      return {
        text: 'Certified',
        color: THEME_PALETTE_LIGHT.primary,
      }
    }

    switch (assessment.currentStatus) {
      case RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.pendingPaymentConfirmation:
        return {
          text: 'Pending payment confirmation',
          color: THEME_PALETTE_LIGHT.warning,
        }
      case RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.inProgress:
        return {
          text: 'In progress',
          color: THEME_PALETTE_LIGHT.primary,
        }
      case RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.inReview:
        return {
          text: 'Under Review',
          color: THEME_PALETTE_LIGHT.danger,
        }
      case RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.passed:
        return {
          text: `${assessment.currentAssessmentName} Passed`,
          color: THEME_PALETTE_LIGHT.primary,
        }

      case RQ_CERTIFIED_ASSESSMENT_STATUS_TYPE.failed:
        return {
          text: 'Failed',
          color: THEME_PALETTE_LIGHT.danger,
        }
      default:
        return null
    }
  }

  const statusConfig = getStatusConfig()

  if (!statusConfig) {
    return null
  }

  return (
    <div
      className={classNames(
        'text-lg font-weight-bold d-flex gap-1',
        inline ? 'flex-row' : 'flex-column',
        className,
      )}
    >
      <div className="text-black">Application Status:</div>
      <div style={{ color: statusConfig.color }}>{statusConfig.text}</div>
    </div>
  )
}

export default RqCertifiedStatusText
