// Install office using brew install --cask microsoft-outlook to get inspector tools

import React, { useEffect, useState } from 'react'

import LoadingWidget from '../components/widgets/LoadingWidget'
import {
  IItemState,
  IMailboxState,
  IOfficeContext,
  OfficeContext,
} from '../contexts/OfficeContext'
import useLayout from '../hooks/useLayout'
import useScript from '../hooks/useScript'
import useSidebar from '../hooks/useSidebar'
import { LAYOUT } from '../lib/constants'
import { isOffice, isOfficeParent } from '../lib/helpers/officeHelpers'

interface Props {
  children: React.ReactNode
}
export const OfficeProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<IOfficeContext>({} as IOfficeContext)
  const sidebar = useSidebar()
  const layout = useLayout()
  const [officeJs] = useScript(
    'https://appsforoffice.microsoft.com/lib/1/hosted/office.js',
  )

  useEffect(() => {
    if (!officeJs) {
      return
    }

    const asyncHandler = async () => {
      // Fix issue with microsoft nullifying history.pushState - https://stackoverflow.com/questions/42642863/office-js-nullifies-browser-history-functions-breaking-history-usage
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      delete history.pushState
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      delete history.replaceState

      // We'll call this function to read the current office state
      // from mailbox, item.
      const update = () => {
        // Unpack the office context.
        const mailbox = Office.context.mailbox
        const item = mailbox?.item
        const getItemState = (): IItemState | undefined => {
          if (!item) return undefined
          const {
            from,
            to,
            internetMessageId,
            conversationId,
            itemType,
            cc,
            body,
          } = item
          return {
            from,
            to,
            cc,
            internetMessageId,
            conversationId,
            itemType,
            body,
          }
        }

        const getMailboxState = (): IMailboxState | undefined => {
          if (!mailbox) return undefined

          const { userProfile } = mailbox

          return {
            userProfile,
            item: getItemState(),
          }
        }

        setState({
          state: {
            mailbox: getMailboxState(),
          },
          isInitialized: true,
        })
      }

      // Update our application, now that Office is initialized.
      update()

      // Update context after changing email
      if (isOfficeParent()) {
        Office.context.mailbox.addHandlerAsync(
          Office.EventType.ItemChanged,
          update,
        )
      }

      if (isOffice()) {
        sidebar.setIsOpen(false)
        layout.setLayout(LAYOUT.FLUID)
      }
    }
    Office.onReady(() => {
      asyncHandler()
    })
  }, [officeJs, sidebar, layout])

  return state.isInitialized ? (
    <OfficeContext.Provider value={state}>{children}</OfficeContext.Provider>
  ) : (
    <LoadingWidget />
  )
}
