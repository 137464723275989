import './bootstrap'

import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'

import { LayoutProvider } from './contexts/LayoutContext'
import { SidebarProvider } from './contexts/SidebarContext'
import { ThemeProvider } from './contexts/ThemeContext'
import queryClient from './lib/queryClient'
import { router } from './router'

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | RQ - Deepen your professional collaboration"
        defaultTitle="RQ - Deepen your professional collaboration"
      />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              <RouterProvider router={router} />
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
