import 'react-app-polyfill/stable'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './App'
import AppErrorBoundary from './components/misc/AppErrorBoundary'
import { reportWebVitals } from './lib/helpers/helperFunctions'
import posthogService from './lib/services/posthogService'
import sentryService from './lib/services/sentryService'
import { store } from './redux/store'

sentryService.init()
posthogService.init()

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <AppErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </AppErrorBoundary>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
