import React, { useEffect, useRef } from 'react'
import { useMount } from 'react-use'

import LoadingWidget from '../components/widgets/LoadingWidget'
import useAppDispatch from '../hooks/useAppDispatch'
import { useCommonDataQuery } from '../hooks/useCommonDataQuery'
import { useCreateSessionMetadataMutation } from '../hooks/useCreateSessionMetadataMutation'
import { useIsPdf } from '../hooks/useIsPdf'
import { useOnboardingChecklistQueryOrFail } from '../hooks/useOnboardingChecklistQueryOrFail'
import { getIdFromIri } from '../lib/helpers/helperFunctions'
import { setOnboardingChecklist } from '../redux/slices/onboardingChecklist'

interface Props {
  children: React.ReactNode
}

const SessionProvider: React.FC<Props> = ({ children }) => {
  const initialiseSessionMutation = useCreateSessionMetadataMutation()
  const initialiseSessionMutationRef = useRef(initialiseSessionMutation)
  const commonDataQuery = useCommonDataQuery()
  const isPdf = useIsPdf()
  const dispatch = useAppDispatch()

  const onboardingChecklistIri =
    initialiseSessionMutation.data?.onboardingChecklist
  const onboardingChecklistId = onboardingChecklistIri
    ? getIdFromIri(onboardingChecklistIri)
    : undefined
  const onboardingChecklistQuery = useOnboardingChecklistQueryOrFail(
    onboardingChecklistId,
  )

  // Run the mutation on mount
  useMount(() => {
    if (!isPdf) {
      initialiseSessionMutationRef.current.mutate()
    }
  })

  useEffect(() => {
    if (onboardingChecklistQuery.data) {
      dispatch(setOnboardingChecklist(onboardingChecklistQuery.data))
    }
  })

  const isLoading =
    initialiseSessionMutation.isPending ||
    commonDataQuery.isLoading ||
    onboardingChecklistQuery.isLoading

  if (isLoading) {
    return <LoadingWidget />
  }

  const error =
    initialiseSessionMutation.error ||
    commonDataQuery.error ||
    onboardingChecklistQuery.error
  if (error) {
    throw error
  }

  return <>{children}</>
}

export default SessionProvider
