import React from 'react'

import { useClientMetadata } from '../../../../../hooks/useClientMetadata'
import { useCompassMetadataOrFail } from '../../../../flows/CompassFlow/utils/useCompassMetadataOrFail'
import CompanyLogoOrName from '../../../../misc/CompanyLogoOrName'
import RequestCompanyCallbackButton from '../../../../misc/RequestCompanyCallbackButton/RequestCompanyCallbackButton'

interface Props {
  includeBroughtBy: boolean
}

const ClientSponsorCompany: React.FC<Props> = ({ includeBroughtBy }) => {
  const clientMetadata = useClientMetadata()
  const compassMetadata = useCompassMetadataOrFail()

  if (!clientMetadata && !compassMetadata) {
    return null
  }

  const sponsorCompany =
    clientMetadata?.sponsorCompany ?? compassMetadata.company

  if (!sponsorCompany) {
    return null
  }

  return (
    <div className="text-center px-4 pb-5">
      {includeBroughtBy && <div className="fw-bold">Introduced by:</div>}

      <CompanyLogoOrName
        name={sponsorCompany.presentationName}
        logoUrl={sponsorCompany.logoUrl}
        showNameFallback={true}
        showLogoFallback={false}
        imageStyle={{ maxWidth: '190px', maxHeight: '60px', marginTop: '10px' }}
        fallbackProps={{ className: 'lead mt-1 text-base' }}
      />

      <div style={{ marginTop: '15px' }}>
        <RequestCompanyCallbackButton
          company={sponsorCompany}
          isCreateReferral={false}
        />
      </div>
    </div>
  )
}

export default ClientSponsorCompany
