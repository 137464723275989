import * as Sentry from '@sentry/react'
import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import RootError from './components/misc/RootError'
import Root from './Root'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
  { path: '*', element: <Root />, errorElement: <RootError /> },
])
