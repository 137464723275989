import invariant from 'tiny-invariant'

import { CompanyTypeCode } from '../../types/misc'
import {
  CompanyTypeItem,
  ServiceAreaItem,
} from '../../types/responses/common-data'
import { COMPANY_TYPE_CODE } from '../constants'

export function getCompanyTypeByCode(
  companyTypes: CompanyTypeItem[],
  companyTypeCode: CompanyTypeCode,
): CompanyTypeItem {
  const companyType = companyTypes.find(
    (companyType) => companyType.code === companyTypeCode,
  )

  invariant(
    companyType,
    `Company type with code '${companyTypeCode}' not found`,
  )

  return companyType
}

export function getCompanyTypeIriByCode(
  companyTypes: CompanyTypeItem[],
  companyTypeCode: CompanyTypeCode,
): IRI {
  return getCompanyTypeByCode(companyTypes, companyTypeCode)['@id']
}

export function getReadableCompanyType(
  companyTypes: CompanyTypeItem[],
  companyCode: CompanyTypeCode,
): string {
  return getCompanyTypeByCode(companyTypes, companyCode).name
}

export function isValidCompanyTypeCode(code: unknown): code is CompanyTypeCode {
  return (
    !!code &&
    typeof code === 'string' &&
    Object.keys(COMPANY_TYPE_CODE).includes(code)
  )
}

export function companyTypeHasServices(
  companyType: CompanyTypeItem,
  serviceAreas: ServiceAreaItem[],
): boolean {
  return serviceAreas.some((serviceArea) =>
    serviceArea.companyTypes.includes(companyType['@id']),
  )
}
