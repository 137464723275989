import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'

import { SerializableFile } from '../../components/flows/PanelInviteFlow/utils/types'
import {
  COMPANY_TYPE_CODE,
  REFERRAL_TYPE,
  REFERRAL_TYPE_OPTION,
} from '../../lib/constants'
import {
  getCompanyTypeByCode,
  getReadableCompanyType,
  isValidCompanyTypeCode,
} from '../../lib/helpers/companyHelpers'
import { getNextStep, getPreviousStep } from '../../lib/helpers/helperFunctions'
import {
  getQueryParam,
  REFER_CLIENT_PARAMS,
} from '../../lib/helpers/routeHelpers'
import referClientService from '../../lib/services/referClientService'
import { ClientDetailsInput } from '../../pages/referClient/steps/3_ClientDetails/constants'
import { buildInitialValues } from '../../pages/referClient/steps/3_ClientDetails/helpers'
import { normalizeReferralType } from '../../pages/referClient/steps/5_SelectReferralMethod/helpers'
import { CompanyTypeCode, ReferralTypeOption } from '../../types/misc'
import { ClientItem } from '../../types/responses/clients'
import {
  CompanyDetailsItem,
  ReferralFirmIndividualsCollection,
} from '../../types/responses/companies'
import { CompassReportCollectionItem } from '../../types/responses/compass-reports'
import { NewReferralMetadataItem } from '../../types/responses/referrals'
import { RootState } from '../store'
import {
  getServiceAreasByIds,
  selectCompanyTypes,
  selectServiceAreas,
} from './commonData'

export interface ReferClientState {
  currentStep: ReferClientStep
  metadata?: NewReferralMetadataItem
  companyTypeCode?: CompanyTypeCode
  referralFirm?: CompanyDetailsItem
  referralFirmIndividuals?: ReferralFirmIndividual[]
  serviceAreaIds: number[]
  client?: ClientItem
  clientDetails?: ClientDetailsInput
  compassReport?: CompassReportCollectionItem
  consentType?: ClientConsentType
  referralType?: ReferralTypeOption
  userEmailMessage?: string
  attachment?: SerializableFile | null
  requestCallbackForm?: {
    callbackTimes?: string[]
    callbackTelephone?: string
  }
}

export type ReferralFirmIndividual =
  ReferralFirmIndividualsCollection['hydra:member'][number]

export type ReferClientStep =
  | 'selectAdviser'
  | 'clientConsent'
  | 'clientDetails'
  | 'commercialAgreement'
  | 'selectReferralMethod'

export const REFER_CLIENT_STEPS: Record<ReferClientStep, ReferClientStep> = {
  selectAdviser: 'selectAdviser',
  clientConsent: 'clientConsent',
  clientDetails: 'clientDetails',
  commercialAgreement: 'commercialAgreement',
  selectReferralMethod: 'selectReferralMethod',
}

export type ClientConsentType = 'needsConsent' | 'alreadyHasConsent'

export const CLIENT_CONSENT_TYPE: Record<ClientConsentType, ClientConsentType> =
  {
    alreadyHasConsent: 'alreadyHasConsent',
    needsConsent: 'needsConsent',
  }

const INITIAL_STATE: ReferClientState = {
  currentStep: REFER_CLIENT_STEPS.selectAdviser,
  serviceAreaIds: [],
}

const sliceName = 'referClient'

export function buildInitialReferClientState(): ReferClientState {
  return {
    ...INITIAL_STATE,

    companyTypeCode: getQueryParam<CompanyTypeCode | undefined>({
      name: REFER_CLIENT_PARAMS.companyTypeCode,
      transform: (value) => (isValidCompanyTypeCode(value) ? value : undefined),
    }),

    serviceAreaIds: getQueryParam<Array<number>>({
      name: REFER_CLIENT_PARAMS.serviceAreaIds,
      transform: (value) => {
        // Ignore invalid values
        if (!(typeof value === 'string')) {
          return []
        }

        // Handle multiple service area IDs
        if (value.includes(',')) {
          return value.split(',').map((id) => Number(id))
        }

        // Handle single service area ID
        return [Number(value)]
      },
    }),

    consentType: getQueryParam<ClientConsentType | undefined>({
      name: REFER_CLIENT_PARAMS.consentType,
      transform: (value) => {
        function isValidConsentType(
          value: unknown,
        ): value is ClientConsentType {
          return (
            typeof value === 'string' &&
            Object.keys(CLIENT_CONSENT_TYPE).includes(value)
          )
        }

        return isValidConsentType(value) ? value : undefined
      },
    }),
  }
}

const referClient = createSlice({
  name: sliceName,
  initialState: buildInitialReferClientState,
  reducers: {
    initialiseReferClient() {
      return buildInitialReferClientState()
    },

    goToStep(state, action: PayloadAction<ReferClientStep>) {
      state.currentStep = action.payload
    },

    setReferClientMetadata: (
      state,
      action: PayloadAction<ReferClientState['metadata']>,
    ) => {
      state.metadata = action.payload
    },

    setCompanyTypeCode: (
      state,
      action: PayloadAction<ReferClientState['companyTypeCode']>,
    ) => {
      state.companyTypeCode = action.payload
      state.serviceAreaIds = []
    },

    setReferralFirm: (
      state,
      action: PayloadAction<ReferClientState['referralFirm']>,
    ) => {
      state.referralFirm = action.payload
    },

    setReferralFirmIndividuals: (
      state,
      action: PayloadAction<ReferClientState['referralFirmIndividuals']>,
    ) => {
      state.referralFirmIndividuals = action.payload
    },

    setServiceAreaIds: (
      state,
      action: PayloadAction<ReferClientState['serviceAreaIds']>,
    ) => {
      state.serviceAreaIds = action.payload
    },

    setClientDetails: (
      state,
      action: PayloadAction<ReferClientState['clientDetails']>,
    ) => {
      state.clientDetails = action.payload
    },

    setClientCircumstances: (state, action: PayloadAction<string>) => {
      if (!state.clientDetails) {
        throw new Error(
          'Tried setting client circumstances without state.clientDetails being set',
        )
      }

      state.clientDetails.circumstances = action.payload
    },

    setClient: (state, action: PayloadAction<ClientItem>) => {
      const previousClient = state.client
      const selectedClient = action.payload

      state.client = selectedClient

      state.clientDetails = {
        ...state.clientDetails,
        firstName: selectedClient.firstName,
        lastName: selectedClient.lastName,
        email: selectedClient.email,
        telephone: selectedClient.telephone || '',
        clientId: selectedClient.clientId || '',
        circumstances: state.clientDetails?.circumstances || '',
      }

      // Clear Compass report if the client has changed
      if (previousClient && selectedClient.id !== previousClient.id) {
        state.compassReport = undefined
      }
    },

    clearClient: (state) => {
      state.client = undefined
      state.compassReport = INITIAL_STATE.compassReport
      state.clientDetails = {
        ...buildInitialValues(INITIAL_STATE.clientDetails),
        circumstances: state.clientDetails?.circumstances || '', // Preserve circumstances
      }
    },

    setCompassReport: (
      state,
      action: PayloadAction<ReferClientState['compassReport']>,
    ) => {
      state.compassReport = action.payload
    },

    clearCompassReportId: (state) => {
      state.compassReport = undefined
    },

    handleReferralFirmChange: (state) => {
      if (state.clientDetails) {
        state.clientDetails.isExpectingCommission = undefined
        state.clientDetails.knowsCommissionAmount = undefined
        state.clientDetails.expectedCommissionAmount = undefined
      }

      state.metadata = undefined
      state.consentType = undefined
      state.referralType = undefined
      state.referralFirmIndividuals = undefined
    },

    setConsentType: (
      state,
      action: PayloadAction<ReferClientState['consentType']>,
    ) => {
      state.consentType = action.payload
    },

    setReferralType: (
      state,
      action: PayloadAction<ReferClientState['referralType']>,
    ) => {
      state.referralType = action.payload
    },

    clearReferralType: (state) => {
      state.referralType = INITIAL_STATE.referralType
    },

    setUserEmailMessage: (
      state,
      action: PayloadAction<ReferClientState['userEmailMessage']>,
    ) => {
      state.userEmailMessage = action.payload
    },

    setAttachment: (
      state,
      action: PayloadAction<ReferClientState['attachment']>,
    ) => {
      state.attachment = action.payload
    },

    setRequestCallbackForm: (
      state,
      action: PayloadAction<ReferClientState['requestCallbackForm']>,
    ) => {
      state.requestCallbackForm = action.payload
    },
  },
})

export const referClientReducer = referClient.reducer

export const {
  initialiseReferClient,
  goToStep,
  setReferClientMetadata,
  setCompanyTypeCode,
  setReferralFirm,
  setReferralFirmIndividuals,
  setServiceAreaIds,
  setClient,
  setClientDetails,
  setClientCircumstances,
  clearClient,
  setCompassReport,
  clearCompassReportId,
  handleReferralFirmChange,
  setConsentType,
  setReferralType,
  clearReferralType,
  setUserEmailMessage,
  setAttachment,
  setRequestCallbackForm,
} = referClient.actions

export const removeReferralFirmSelection = createAsyncThunk(
  `${sliceName}/removeReferralFirmSelection`,
  async (_, { dispatch }) => {
    dispatch(handleReferralFirmChange())
    dispatch(setReferralFirm(undefined))

    const url = new URL(window.location.href)
    const params = url.searchParams
    params.delete(REFER_CLIENT_PARAMS.referralFirmId)
    window.history.pushState({}, '', url)
  },
)

const REFER_CLIENT_STEP_NAMES = Object.keys(
  REFER_CLIENT_STEPS,
) as ReferClientStep[]

export type ReferClientSteps = Array<keyof typeof REFER_CLIENT_STEPS>

export const selectReferClient = (state: RootState) => state.referClient

export const selectCurrentStep = createSelector(
  selectReferClient,
  (state) => state.currentStep,
)

export const selectNextStep = createSelector(
  selectCurrentStep,
  (currentStep) => {
    return getNextStep<ReferClientSteps>(REFER_CLIENT_STEP_NAMES, currentStep)
  },
)

export const selectPreviousStep = createSelector(
  selectCurrentStep,
  (currentStep) => {
    return getPreviousStep<ReferClientSteps>(
      REFER_CLIENT_STEP_NAMES,
      currentStep,
    )
  },
)

export const selectReferClientMetadata = createSelector(
  selectReferClient,
  (state) => state.metadata,
)

export const selectReferClientMetadataOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(state.metadata, 'Expected metadata to be set')

    return state.metadata
  },
)

export const selectCompanyTypeCode = createSelector(
  selectReferClient,
  (state) => state.companyTypeCode,
)

export const selectSelectedCompanyType = createSelector(
  selectCompanyTypes,
  selectCompanyTypeCode,
  (companyTypes, selectedCompanyTypeCode) =>
    selectedCompanyTypeCode
      ? getCompanyTypeByCode(companyTypes, selectedCompanyTypeCode)
      : null,
)

export const selectReadableCompanyType = createSelector(
  selectCompanyTypes,
  selectCompanyTypeCode,
  (companyTypes, selectedCompanyTypeCode) =>
    selectedCompanyTypeCode
      ? getReadableCompanyType(companyTypes, selectedCompanyTypeCode)
      : undefined,
)

export const selectIsReferringToIfa = createSelector(
  selectCompanyTypeCode,
  (companyTypeCode) => companyTypeCode === COMPANY_TYPE_CODE.ifa,
)

export const selectReferralFirm = createSelector(
  selectReferClient,
  (state) => state.referralFirm,
)

export const selectReferralFirmOrFail = createSelector(
  selectReferralFirm,
  (referralFirm) => {
    invariant(referralFirm, 'Expected referralFirm to be set')

    return referralFirm
  },
)

export const selectReferralFirmIndividuals = createSelector(
  selectReferClient,
  (state) => state.referralFirmIndividuals,
)

export const selectServiceAreaIds = createSelector(
  selectReferClient,
  (state) => state.serviceAreaIds,
)

export const selectSelectedServiceAreas = createSelector(
  selectServiceAreas,
  selectServiceAreaIds,
  (serviceAreas, serviceAreaIds) => {
    return getServiceAreasByIds(serviceAreas, serviceAreaIds)
  },
)

export const selectClient = createSelector(
  selectReferClient,
  (state) => state.client,
)

export const selectClientOrFail = createSelector(selectClient, (client) => {
  invariant(client, 'Expected client to be set')

  return client
})

export const selectClientId = createSelector(
  selectClient,
  (client) => client?.id,
)

export const selectClientDetails = createSelector(
  selectReferClient,
  (state) => state.clientDetails,
)

export const selectClientDetailsOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(state.clientDetails, 'Expected state.clientDetails to be set')

    return state.clientDetails
  },
)

export const selectCompassReport = createSelector(
  selectReferClient,
  (state) => state.compassReport,
)

export const selectConsentType = createSelector(
  selectReferClient,
  (state) => state.consentType,
)

export const selectNeedsClientConsent = createSelector(
  selectReferClient,
  (state) => state.consentType === CLIENT_CONSENT_TYPE.needsConsent,
)

export const selectReferralTypeOption = createSelector(
  selectReferClient,
  (state) => state.referralType,
)

export const selectIsNormalReferralType = createSelector(
  selectReferralTypeOption,
  (referralTypeOption) =>
    referClientService.isNormalReferralType(referralTypeOption),
)

export const selectIsBookingCallForClient = createSelector(
  selectReferralTypeOption,
  (referralTypeOption) =>
    referClientService.isBookCallForClientReferralType(referralTypeOption),
)

export const selectIsRequestingReferralCallbackForClient = createSelector(
  selectIsBookingCallForClient,
  selectReferClientMetadata,
  (isBookingCallForClient, metadata) => {
    return isBookingCallForClient && !metadata?.toCompany.scheduleClientCallUrl
  },
)

export const selectIsSchedulingMeetingForClient = createSelector(
  selectIsBookingCallForClient,
  selectReferClientMetadata,
  (isBookingCallForClient, metadata) => {
    return isBookingCallForClient && metadata?.toCompany.scheduleClientCallUrl
  },
)
export const selectNormalizedReferralType = createSelector(
  selectReferralTypeOption,
  (referralType) => normalizeReferralType(referralType),
)

export const selectIsDirectReferral = createSelector(
  selectReferralTypeOption,
  (referralType) => {
    return (
      referralType === REFERRAL_TYPE.direct ||
      referralType === REFERRAL_TYPE.indirect
    )
  },
)

export const selectShouldCcClientOnInitialEmail = createSelector(
  selectReferralTypeOption,
  (referralType) => {
    return referralType === REFERRAL_TYPE_OPTION.directCc
  },
)

export const selectUserEmailMessage = createSelector(
  selectReferClient,
  (referClient) => referClient.userEmailMessage,
)

export const selectAttachment = createSelector(
  selectReferClient,
  (referClient) => referClient.attachment,
)

export const selectRequestCallbackForm = createSelector(
  selectReferClient,
  (referClient) => referClient.requestCallbackForm,
)
