import posthog from 'posthog-js'

import { SessionMetadataUser } from '../../types/responses/session-metadata'
import { isStagingOrProduction } from '../helpers/helperFunctions'

class PosthogService {
  init() {
    if (!this.isEnabled()) {
      return
    }

    posthog.init(process.env.REACT_APP_POSTHOG_TOKEN, {
      api_host: 'https://ph.rqratings.com',
      autocapture: window.location.hostname !== 'localhost',
      disable_session_recording: window.location.hostname === 'localhost',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    })
  }

  isEnabled() {
    return isStagingOrProduction()
  }

  identifyUser(user: SessionMetadataUser) {
    if (!this.isEnabled()) {
      return
    }

    if (user && !user.impersonated) {
      posthog.identify(user.keycloakId, {
        email: user.email,
        name: user.fullName,
      })

      posthog.capture('Login')
    }
  }
}

const posthogService = new PosthogService()

export default posthogService
