import { PersonalAssetsInput } from '../../../components/flows/CompassFlow/utils/types'
import { matchesPath } from '../../helpers/routeHelpers'
import { ROUTES } from '../../routes'

class CompassFlowService {
  isReadOnlyMode(): boolean {
    return matchesPath(window.location.pathname, ROUTES.companyCompassView)
  }

  shouldShowRentalIncomeQuestion(options: {
    personalAssets?: PersonalAssetsInput
    isForPartner?: boolean
  }): boolean {
    const { personalAssets, isForPartner = false } = options

    // If the user and their partner have a joint rental property, we always
    // want to ask both for their rental incomes (even if one of them said
    // they don't own a rental property in their own name).
    if (personalAssets?.joint_has_rental_property) {
      return true
    }

    return isForPartner
      ? !!personalAssets?.partner_has_rental_property
      : !!personalAssets?.user_has_rental_property
  }
}

const compassFlowService = new CompassFlowService()

export default compassFlowService
