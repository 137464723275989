import React from 'react'
import { Card } from 'react-bootstrap'

import ErrorMessage from './ErrorMessage'

const ErrorScreen: React.FC<{ error: unknown }> = ({ error }) => (
  <div
    className="mt-5 p-4 mx-auto d-flex justify-content-center"
    style={{ maxWidth: '800px' }}
  >
    <Card>
      <Card.Body>
        <ErrorMessage error={error} />
      </Card.Body>
    </Card>
  </div>
)

export default ErrorScreen
