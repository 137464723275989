import {
  COMMERCIAL_AGREEMENT_DIRECTION,
  COMMERCIAL_AGREEMENT_TYPE_OPTION,
} from '../../components/misc/CommercialAgreementsForm/constants'
import { CommercialAgreementTypeOption } from '../../redux/slices/commercialAgreementsForm'
import {
  CommercialAgreementDirectionType,
  CommercialAgreementReferralDirection,
  CommercialAgreementType,
} from '../../types/misc'
import {
  ConfirmCommercialAgreementRequest,
  CreateCommercialAgreementMetadataRequest,
  CreateCommercialAgreementRequest,
  CreateExampleCommercialAgreementTextRequest,
  ValidateCommercialAgreementRequest,
} from '../../types/requests/commercial-agreements'
import {
  CommercialAgreementItem,
  CommercialAgreementsMetadataItem,
  CreateExampleCommercialAgreementResponse,
} from '../../types/responses/commercial-agreements'
import {
  COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION,
  COMMERCIAL_AGREEMENT_TYPE,
} from '../constants'
import apiService from './apiService'
import serviceFeeService from './serviceFeeService'

class CommercialAgreementService {
  private endpoint = '/v1/commercial-agreements'

  async getAgreement(agreementId: number): Promise<CommercialAgreementItem> {
    const response = await apiService.get(`${this.endpoint}/${agreementId}`)
    return response.data
  }

  async createCommercialAgreement(
    request: CreateCommercialAgreementRequest,
  ): Promise<unknown> {
    request.serviceFees = request.serviceFees.map((serviceFee) =>
      serviceFeeService.normalizeServiceFee(serviceFee),
    )
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  hasCommercialAgreement(
    agreementType?: CommercialAgreementTypeOption,
  ): agreementType is CommercialAgreementType {
    return (
      agreementType === COMMERCIAL_AGREEMENT_TYPE_OPTION.YFS ||
      agreementType === COMMERCIAL_AGREEMENT_TYPE_OPTION.YJV ||
      agreementType === COMMERCIAL_AGREEMENT_TYPE_OPTION.RQP
    )
  }

  async confirmAgreement(
    currentCompanyId: number,
    agreementId: number,
    request?: ConfirmCommercialAgreementRequest,
  ) {
    const response = await apiService.post(
      `${this.endpoint}/${agreementId}/company/${currentCompanyId}/approve`,
      request || {},
    )
    return response.data
  }

  async createExampleAgreementText(
    request: CreateExampleCommercialAgreementTextRequest,
  ): Promise<CreateExampleCommercialAgreementResponse> {
    const response = await apiService.post(`${this.endpoint}/example`, request)
    return response.data
  }

  async amendAgreement(agreementId: number, text: string) {
    const response = await apiService.post(
      `${this.endpoint}/${agreementId}/update`,
      { text },
    )

    return response.data
  }

  async createMetadata(
    request: CreateCommercialAgreementMetadataRequest,
  ): Promise<CommercialAgreementsMetadataItem> {
    const response = await apiService.post(`${this.endpoint}/metadata`, request)

    return response.data
  }

  async validateAgreement(values: ValidateCommercialAgreementRequest) {
    const response = await apiService.post(`${this.endpoint}/validate`, values)

    return response.data
  }

  isOutgoingAgreement(
    referralDirection: CommercialAgreementReferralDirection,
  ): boolean {
    return (
      referralDirection === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING
    )
  }

  isIncomingAgreement(
    referralDirection: CommercialAgreementReferralDirection,
  ): boolean {
    return (
      referralDirection === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING
    )
  }

  shouldShowExampleAgreementText(
    agreementType: CommercialAgreementTypeOption | undefined,
  ): boolean {
    if (!agreementType) {
      return false
    }

    if (agreementType === COMMERCIAL_AGREEMENT_TYPE.YFS) {
      return true
    }

    if (agreementType === COMMERCIAL_AGREEMENT_TYPE.YJV) {
      return true
    }

    return true
  }

  isValidReferralDirection(
    value: string,
  ): value is CommercialAgreementReferralDirection {
    return (
      value === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING ||
      value === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING
    )
  }

  isValidCommercialAgreementType(
    value: unknown,
  ): value is CommercialAgreementType {
    return (
      typeof value === 'string' && !!value && value in COMMERCIAL_AGREEMENT_TYPE
    )
  }

  getAgreementTypeLabel(
    commercialAgreementType: CommercialAgreementType | undefined,
  ) {
    const labels: Record<CommercialAgreementType, string> = {
      YFS: 'Fee sharing',
      YJV: 'Joint Venture',
      RQP: 'RQ Partnership',
      NO: 'No commercial agreement in place',
    }

    return commercialAgreementType && labels[commercialAgreementType]
      ? labels[commercialAgreementType]
      : labels.NO
  }

  isFeeSharingAgreement(
    agreementType?: string | null,
  ): agreementType is CommercialAgreementType {
    return agreementType === COMMERCIAL_AGREEMENT_TYPE.YFS
  }

  isJointVentureAgreement(
    agreementType?: string,
  ): agreementType is CommercialAgreementType {
    return agreementType === COMMERCIAL_AGREEMENT_TYPE.YJV
  }

  isRqPartnershipAgreement(
    agreementType?: string | null,
  ): agreementType is CommercialAgreementType {
    return agreementType === COMMERCIAL_AGREEMENT_TYPE.RQP
  }

  isValidCommercialAgreementDirectionType(
    value?: string | null,
  ): value is CommercialAgreementDirectionType {
    const validValues: string[] = Object.values(COMMERCIAL_AGREEMENT_DIRECTION)

    return !!value && validValues.includes(value)
  }

  shouldShowClientDisclosureConfirmation(options: {
    agreementType: CommercialAgreementTypeOption
    isIcaewRegulated: boolean
    referralDirection: CommercialAgreementReferralDirection
  }) {
    const { agreementType, isIcaewRegulated, referralDirection } = options

    return (
      this.isOutgoingAgreement(referralDirection) &&
      isIcaewRegulated &&
      (this.isFeeSharingAgreement(agreementType) ||
        this.isJointVentureAgreement(agreementType))
    )
  }

  shouldShowFeePassBackQuestions(options: {
    agreementType?: CommercialAgreementTypeOption
    referralDirection: CommercialAgreementReferralDirection
  }) {
    return (
      this.isFeeSharingAgreement(options.agreementType) &&
      this.isOutgoingAgreement(options.referralDirection)
    )
  }

  shouldAskForServiceFees(
    agreementType: CommercialAgreementTypeOption | undefined,
  ) {
    return (
      this.isFeeSharingAgreement(agreementType) ||
      this.isRqPartnershipAgreement(agreementType)
    )
  }

  shouldShowServiceFees(
    agreementType: CommercialAgreementTypeOption | undefined,
  ) {
    return this.isFeeSharingAgreement(agreementType)
  }
}

const commercialAgreementService = new CommercialAgreementService()

export default commercialAgreementService
