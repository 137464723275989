import { useField, useFormikContext } from 'formik'
import React, { CSSProperties, useMemo } from 'react'

import { MONTH_OPTION_LABELS } from '../../lib/constants'
import FieldError from './FieldError'
import FieldLabel, { TooltipHint } from './FieldLabel'
import HelpText from './HelpText'

interface Props {
  monthName: string
  yearName: string
  label?: React.ReactNode
  tooltipHint?: TooltipHint
  helpText?: React.ReactNode
  type: 'future' | 'past'
  isDisabled?: boolean
  showYearsFromNow?: boolean
}

const MonthYearInput: React.FC<Props> = ({
  monthName,
  yearName,
  label,
  tooltipHint,
  helpText,
  type,
  isDisabled,
  showYearsFromNow,
}) => {
  const { submitCount, setFieldValue } = useFormikContext()
  const [monthField, { touched: monthTouched, error: monthError }] =
    useField(monthName)
  const [yearField, { touched: yearTouched, error: yearError }] =
    useField(yearName)

  const id = `field_${monthName}_${yearName}`
  const showError = !!(
    (submitCount > 0 && monthTouched && monthError) ||
    (yearTouched && yearError)
  )

  const currentYear = new Date().getFullYear()

  const selectControlStyles: CSSProperties = {
    width: '80px',
  }

  const monthOptions = useMemo(() => {
    return new Array(12).fill(undefined).map((_, index) => {
      const month = index + 1
      const label = MONTH_OPTION_LABELS[index]

      return (
        <option key={month} value={month}>
          {label}
        </option>
      )
    })
  }, [])

  function getFutureYears() {
    const numYears = 100
    const startingYear = currentYear

    return new Array(numYears).fill(undefined).map((_, index) => {
      const year = startingYear + index

      return (
        <option key={year} value={year}>
          {showYearsFromNow ? `${year}${renderYearFromNowText(year)}` : year}
        </option>
      )
    })
  }

  function renderYearFromNowText(year: number) {
    const yearsFromNow = year - currentYear

    if (yearsFromNow === 0) {
      return ' (this year)'
    }

    if (yearsFromNow === 1) {
      return ' (1 year from now)'
    }

    return ` (${yearsFromNow} years from now)`
  }

  function getPastYears() {
    const currentYear = new Date().getFullYear()
    const minAge = 0
    const maxAge = 150
    const startingYear = currentYear - minAge

    return new Array(maxAge - minAge).fill(undefined).map((_, index) => {
      const year = startingYear - index

      return (
        <option key={year} value={year}>
          {year}
        </option>
      )
    })
  }

  const yearOptions = type === 'past' ? getPastYears() : getFutureYears()

  // TODO: Temporary work around to deal with Compass API errors whilst proper
  // error handling is implemented on the API side.
  function formatErrorMessage(errorMessage?: string) {
    return !!errorMessage &&
      ['Must be a number', 'This field is required'].includes(errorMessage)
      ? 'Please select a date'
      : errorMessage
  }

  return (
    <>
      {label && (
        <FieldLabel htmlFor={id} tooltipHint={tooltipHint}>
          {label}
        </FieldLabel>
      )}

      <div className="d-flex">
        <select
          value={monthField.value}
          onChange={(event) => setFieldValue(monthName, event.target.value)}
          className="form-select me-2"
          style={selectControlStyles}
          disabled={isDisabled}
        >
          <option value="">MMM</option>
          {monthOptions}
        </select>

        <select
          value={yearField.value}
          onChange={(event) => setFieldValue(yearName, event.target.value)}
          className="form-select"
          style={selectControlStyles}
          disabled={isDisabled}
        >
          <option value="">YYYY</option>
          {yearOptions}
        </select>
      </div>

      {showError && (
        <FieldError inputId={id}>
          {formatErrorMessage(monthError || yearError)}
        </FieldError>
      )}

      {helpText && <HelpText>{helpText}</HelpText>}
    </>
  )
}

export default MonthYearInput
