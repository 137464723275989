import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

interface CompassPartnerState {
  hasPartners?: boolean
}

const INITIAL_STATE: CompassPartnerState = {
  hasPartners: undefined,
}

export const compassPartner = createSlice({
  name: 'compassPartner',
  initialState: INITIAL_STATE,
  reducers: {
    setHasCompassPartners(
      state,
      action: PayloadAction<CompassPartnerState['hasPartners']>,
    ) {
      state.hasPartners = action.payload
    },
  },
})

export const { setHasCompassPartners } = compassPartner.actions

export const selectCompassPartner = (state: RootState) => state.compassPartner

export const selectHasCompassPartners = createSelector(
  selectCompassPartner,
  (compassPartner) => compassPartner.hasPartners,
)

export const compassPartnerReducer = compassPartner.reducer
