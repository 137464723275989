import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { Outlet } from 'react-router'

import { useIsPdf } from '../../hooks/useIsPdf'
import { useScrollToTopOnRouteChange } from '../../hooks/useScrollToTopOnRouteChange'
import ClientMetadataProvider from '../../providers/ClientMetadataProvider'
import { OfficeProvider } from '../../providers/OfficeProvider'
import SessionProvider from '../../providers/SessionProvider'
import LogoutWarning from '../misc/LogoutWarning'
import NetworkStatusBar from '../misc/NetworkStatusBar'
import HubspotChatWidget from '../widgets/HubspotChatWidget'

const AppLayout: React.FC = () => {
  useScrollToTopOnRouteChange()

  const isPdf = useIsPdf()
  const shouldShowChatWidget = process.env.REACT_APP_HUBSPOT_ID && !isPdf

  return (
    <OfficeProvider>
      <LogoutWarning />
      <SessionProvider>
        <ClientMetadataProvider>
          <Outlet />
          {shouldShowChatWidget && <HubspotChatWidget />}
          {!isPdf && <ReactQueryDevtools buttonPosition="bottom-left" />}
          <NetworkStatusBar />
        </ClientMetadataProvider>
      </SessionProvider>
    </OfficeProvider>
  )
}

export default AppLayout
