import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import React, { lazy, Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import ErrorFallback from './components/flows/CompassFlow/components/ErrorFallback'
import AuthGuard from './components/guards/AuthGuard'
import CompassGuard from './components/guards/CompassGuard'
import GuestGuard from './components/guards/GuestGuard'
import AppLayout from './components/layouts/AppLayout'
import ClientDashboardLayout from './components/layouts/DashboardLayout/ClientDashboardLayout'
import CompanyDashboardLayout from './components/layouts/DashboardLayout/CompanyDashboardLayout'
import IcaewDashboardLayout from './components/layouts/DashboardLayout/IcaewDashboardLayout'
import LandingBlackLayout from './components/layouts/LandingBlackLayout/LandingBlackLayout'
import LandingGreyLayout from './components/layouts/LandingGreyLayout'
import LandingWhiteLayout from './components/layouts/LandingWhiteLayout/LandingWhiteLayout'
import MinimalLayout from './components/layouts/MinimalLayout'
import OfficeLayout from './components/layouts/OfficeLayout'
import RqCertifiedLayout from './components/layouts/RqCertifiedLayout/RqCertifiedLayout'
import LoadingWidget from './components/widgets/LoadingWidget'
import { ROUTES } from './lib/routes'
import ClientPaymentPage from './pages/ClientPaymentPage'
import IndexPage from './pages/IndexPage'
import { USER_TYPE } from './types/misc'

const AddCompanyPage = lazy(
  () =>
    import(/* webpackChunkName: "AddCompanyPage" */ './pages/AddCompanyPage'),
)

const AddRelationshipPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AddRelationshipPage" */ './pages/AddRelationshipPage'
    ),
)

const AddExistingAdviserPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AddExistingAdviserPage" */ './pages/AddExistingAdviserPage'
    ),
)

const AddRelationshipAgreementPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AddRelationshipAgreementPage" */ './pages/addRelationshipAgreement/AddRelationshipAgreementPage'
    ),
)

const BookMeetingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "BookMeetingPage" */ './pages/bookMeeting/BookMeetingPage'
    ),
)

const CompanyProfilePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyProfilePage" */ './pages/companyProfile/CompanyProfilePage'
    ),
)

const ContactsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ContactsPage" */ './pages/contacts/ContactsPage'
    ),
)

const CompanyCompassPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyCompassPage" */ './pages/companyCompass/CompanyCompassPage'
    ),
)

const CompanyCompassViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyCompassViewPage" */ './pages/companyCompassView/CompanyCompassViewPage'
    ),
)

const CompanyCompassDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyCompassDashboardPage" */ './pages/companyCompassDashboard/CompanyCompassDashboardPage'
    ),
)

const CompanyCompassGlobalLinkPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyCompassGlobalLinkPage" */ './pages/companyCompassGlobalLink/CompanyCompassGlobalLinkPage'
    ),
)

const CompanyCompassInvitePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyCompassInvitePage" */ './pages/companyCompassInvite/CompanyCompassInvitePage'
    ),
)

const CompanyCompassInvitePreviewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyCompassInvitePreviewPage" */ './pages/companyCompassInvitePreview/CompanyCompassInvitePreviewPage'
    ),
)

const CompanyCompassSnapshotsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompanyCompassSnapshotsPage" */ './pages/companyCompassSnapshots/CompanyCompassSnapshotsPage'
    ),
)

const CompassActionsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassActionsPage" */ './pages/compassActions/CompassActionsPage'
    ),
)

const CompassConfirmSharingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassConfirmSharingPage" */ './pages/compassConfirmSharing/CompassConfirmSharingPage'
    ),
)

const CompassConfirmStartPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassConfirmStartPage" */ './pages/compassConfirmStart/CompassConfirmStartPage'
    ),
)

const CompassConfirmSharingViaGlobalLinkPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassConfirmSharingViaGlobalLinkPage" */ './pages/compassConfirmSharingViaGlobalLink/CompassConfirmSharingViaGlobalLinkPage'
    ),
)

const CompassDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassDashboardPage" */ './pages/compassDashboard/CompassDashboardPage'
    ),
)

const CompassDetailsAlreadyOnFilePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassDetailsAlreadyOnFilePage" */ './pages/compassDetailsAlreadyOnFile/CompassDetailsAlreadyOnFilePage'
    ),
)

const CompassHowItWorksPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassHowItWorks" */ './pages/compassHowItWorks/CompassHowItWorksPage'
    ),
)

const CompassPartnersPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassPartnersPage" */ './pages/compassPartners/CompassPartnersPage'
    ),
)

const CompassPdfGuidePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassPdfGuidePage" */ './pages/compassPdfGuide/CompassPdfGuidePage'
    ),
)

const CompassPdfReportPreviewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassPdfReportPreviewPage" */ './pages/compassPdfReportPreview/CompassPdfReportPreviewPage'
    ),
)

const CompassPdfReportSectionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassPdfReportSectionPage" */ './pages/compassPdfReportSection/CompassPdfReportSectionPage'
    ),
)

const CompassRedirectPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassRedirectPage" */ './pages/compassRedirect/CompassRedirectPage'
    ),
)

const CompassSharingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassSharingPage" */ './pages/compassSharing/CompassSharingPage'
    ),
)

const CompassSnapshotPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassSnapshotPage" */ './pages/compassSnapshot/CompassSnapshotPage'
    ),
)

const CompassSnapshotsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassSnapshotsPage" */ './pages/compassSnapshots/CompassSnapshotsPage'
    ),
)

const CompassStartPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassStartPage" */ './pages/compassStart/CompassStartPage'
    ),
)

const CompassUpdatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompassUpdatePage" */ './pages/compassUpdate/CompassUpdatePage'
    ),
)

const ConfirmConsentPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmConsentPage" */ './pages/confirmConsent/ConfirmConsentPage'
    ),
)

const DemoVideosPage = lazy(
  () =>
    import(
      /* webpackChunkName: "DemoVideosPage" */ './pages/demoVideos/DemoVideosPage'
    ),
)

const DisclaimerPage = lazy(
  () =>
    import(/* webpackChunkName: "DisclaimerPage" */ './pages/DisclaimerPage'),
)

const FindProfessionalPage = lazy(
  () =>
    import(
      /* webpackChunkName: "FindProfessionalPage" */ './pages/FindProfessionalPage'
    ),
)

const GettingStartedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "GettingStartedPage" */ './pages/gettingStarted/GettingStartedPage'
    ),
)

const IcaewOverviewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AddCompanyPage" */ './pages/IcaewOverviewPage'
    ),
)

const IcaewFirmSearchPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AddCompanyPage" */ './pages/icaewFirmSearch/IcaewFirmSearchPage'
    ),
)

const IcaewTrendsPage = lazy(
  () =>
    import(/* webpackChunkName: "AddCompanyPage" */ './pages/IcaewTrendsPage'),
)

const InvalidOrExpiredPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InvalidOrExpiredPage" */ './pages/InvalidOrExpiredPage'
    ),
)

const InvitationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InvitationPage" */ './pages/invitation/InvitationPage'
    ),
)

const InviteUserPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InviteUserPage" */ './pages/inviteUser/InviteUserPage'
    ),
)

const ManageUsersPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ManageUsersPage" */ './pages/manageUsers/ManageUsersPage'
    ),
)

const NotFoundPage = lazy(
  () => import(/* webpackChunkName: "NotFoundPage" */ './pages/NotFoundPage'),
)

const ImportContactsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ImportContactsPage" */ './pages/importContacts/ImportContactsPage'
    ),
)

const ImportReferralsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ImportReferralsPage" */ './pages/importReferrals/ImportReferralsPage'
    ),
)

const InviteToRqPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InviteToRqPage" */ './pages/inviteToRq/InviteToRqPage'
    ),
)

const PreferencesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PreferencesPage" */ './pages/preferences/PreferencesPage'
    ),
)

const RelationshipStatsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RelationshipsStatsPage" */ './pages/relationshipStats/RelationshipStatsPage'
    ),
)

const ReferClientPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferClientPage" */ './pages/referClient/ReferClientPage'
    ),
)

const ReferralCommissionConsentPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralCommissionConsentPage" */ './pages/referralCommissionConsent/ReferralCommissionConsentPage'
    ),
)

const ReferralsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralsPage" */ './pages/referrals/ReferralsPage'
    ),
)

const ReferralsDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralsDashboardPage" */ './pages/referralsDashboard/ReferralsDashboardPage'
    ),
)

const ReferralsImportPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralsDashboardPage" */ './pages/referralsImport/ReferralsImportPage'
    ),
)

const ReferralPartnersPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralPartnersPage" */ './pages/referralPartners/ReferralPartnersPage'
    ),
)

const ReferralPartnersApprovalsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralPartnersApprovalsPage" */ './pages/referralPartnersApprovals/ReferralPartnersApprovalsPage'
    ),
)

const ReferralSummaryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferralSummaryPage" */ './pages/referralSummary/ReferralSummaryPage'
    ),
)
const RegisterPage = lazy(
  () => import(/* webpackChunkName: "RegisterPage" */ './pages/RegisterPage'),
)

const RequestCallbackPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RequestCallbackPage" */ './pages/requestCallback/RequestCallbackPage'
    ),
)

const RqCertifiedApplicationStatusPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RqCertifiedApplicationStatusPage" */ './pages/rqCertifiedApplicationStatus/RqCertifiedApplicationStatusPage'
    ),
)

const RqCertifiedApplicationProcessPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RqCertifiedApplicationProcessPage" */ './pages/rqCertifiedApplicationProcess/RqCertifiedApplicationProcessPage'
    ),
)

const RqCertifiedGlossaryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RqCertifiedGlossaryPage" */ './pages/rqCertifiedGlossary/RqCertifiedGlossaryPage'
    ),
)

const RqCertifiedNdaPdfPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RqCertifiedNdaPdfPage" */ './pages/rqCertifiedNdaPdf/RqCertifiedNdaPdfPage'
    ),
)

const RqCertifiedPdfGuidesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RqCertifiedPdfGuidesPage" */ './pages/rqCertifiedPdfGuides/RqCertifiedPdfGuidesPage'
    ),
)

const ShortlistPage = lazy(
  () => import(/* webpackChunkName: "ShortlistPage" */ './pages/ShortlistPage'),
)

const SignInPage = lazy(
  () => import(/* webpackChunkName: "SignInPage" */ './pages/SignInPage'),
)

const SignOutPage = lazy(
  () => import(/* webpackChunkName: "SignOutPage" */ './pages/SignOutPage'),
)

const SuspendedPage = lazy(
  () => import(/* webpackChunkName: "SuspendedPage" */ './pages/SuspendedPage'),
)

const UserCompassPage = lazy(
  () =>
    import(
      /* webpackChunkName: "UserCompassPage" */ './pages/userCompass/UserCompassPage'
    ),
)

const WelcomePage = lazy(
  () => import(/* webpackChunkName: "WelcomePage" */ './pages/WelcomePage'),
)

const WithdrawGdprConsentPage = lazy(
  () =>
    import(
      /* webpackChunkName: "WithdrawGdprConsentPage" */ './pages/withdrawGdprConsent/WithdrawGdprConsentPage'
    ),
)

const Office365TaskPanePage = lazy(
  () =>
    import(
      /* webpackChunkName: "Office365TaskPanePage" */ './pages/office365TaskPane/Office365TaskPanePage'
    ),
)

interface AppRoute {
  path: string
  element: React.ReactNode
}

const accountSetupRoutes: AppRoute[] = [
  {
    path: ROUTES.register,
    element: <RegisterPage />,
  },
  {
    path: ROUTES.welcome,
    element: <WelcomePage />,
  },
]

const guestRoutes: AppRoute[] = [
  {
    path: ROUTES.index,
    element: <IndexPage />,
  },
  {
    path: ROUTES.signIn,
    element: (
      <GuestGuard>
        <SignInPage />
      </GuestGuard>
    ),
  },
  {
    path: ROUTES.signOut,
    element: <SignOutPage />,
  },
]

const companyDashboardRoutes: AppRoute[] = [
  { path: ROUTES.addCompany, element: <AddCompanyPage /> },
  { path: ROUTES.addExistingAdviser, element: <AddExistingAdviserPage /> },
  {
    path: ROUTES.addRelationshipAgreement,
    element: <AddRelationshipAgreementPage />,
  },
  { path: ROUTES.addRelationship, element: <AddRelationshipPage /> },
  { path: ROUTES.bookMeeting, element: <BookMeetingPage /> },
  { path: ROUTES.companyProfile, element: <CompanyProfilePage /> },
  {
    path: ROUTES.companyCompassDashboard,
    element: <CompanyCompassDashboardPage />,
  },
  {
    path: ROUTES.companyCompassGlobalLink,
    element: <CompanyCompassGlobalLinkPage />,
  },
  {
    path: ROUTES.companyCompassSnapshots,
    element: <CompanyCompassSnapshotsPage />,
  },
  {
    path: ROUTES.compassConfirmStart,
    element: <CompassConfirmStartPage />,
  },
  { path: ROUTES.contacts, element: <ContactsPage /> },
  { path: ROUTES.demoVideos, element: <DemoVideosPage /> },
  { path: ROUTES.disclaimer, element: <DisclaimerPage /> },
  { path: ROUTES.importContacts, element: <ImportContactsPage /> },
  { path: ROUTES.inviteToRq, element: <InviteToRqPage /> },
  { path: ROUTES.findProfessional, element: <FindProfessionalPage /> },
  { path: ROUTES.gettingStarted, element: <GettingStartedPage /> },
  { path: ROUTES.manageUsers, element: <ManageUsersPage /> },
  { path: ROUTES.preferences, element: <PreferencesPage /> },
  { path: ROUTES.referClient, element: <ReferClientPage /> },
  { path: ROUTES.referrals, element: <ReferralsPage /> },
  { path: ROUTES.referralsDashboard, element: <ReferralsDashboardPage /> },
  {
    path: ROUTES.referralsImport,
    element: <ReferralsImportPage />,
  },
  {
    path: ROUTES.referralsImportIndividualUpload,
    element: <ImportReferralsPage />,
  },
  { path: ROUTES.referralSummary, element: <ReferralSummaryPage /> },
  { path: ROUTES.referralPartners, element: <ReferralPartnersPage /> },
  {
    path: ROUTES.referralPartnersApprovals,
    element: <ReferralPartnersApprovalsPage />,
  },
  { path: ROUTES.relationshipStats, element: <RelationshipStatsPage /> },
  {
    path: ROUTES.rqCertifiedApplicationProcess,
    element: (
      <RqCertifiedLayout>
        <RqCertifiedApplicationProcessPage />
      </RqCertifiedLayout>
    ),
  },
  {
    path: ROUTES.rqCertifiedApplicationStatus,
    element: (
      <RqCertifiedLayout>
        <RqCertifiedApplicationStatusPage />
      </RqCertifiedLayout>
    ),
  },
  {
    path: ROUTES.rqCertifiedGlossary,
    element: (
      <RqCertifiedLayout>
        <RqCertifiedGlossaryPage />
      </RqCertifiedLayout>
    ),
  },
  {
    path: ROUTES.rqCertifiedPdfGuides,
    element: (
      <RqCertifiedLayout>
        <RqCertifiedPdfGuidesPage />
      </RqCertifiedLayout>
    ),
  },
]

const clientDashboardRoutes: AppRoute[] = [
  { path: ROUTES.compassActions, element: <CompassActionsPage /> },
  {
    path: ROUTES.compassConfirmSharing,
    element: <CompassConfirmSharingPage />,
  },
  {
    path: ROUTES.compassConfirmSharingViaGlobalLink,
    element: <CompassConfirmSharingViaGlobalLinkPage />,
  },
  { path: ROUTES.compassDashboard, element: <CompassDashboardPage /> },
  {
    path: ROUTES.compassDetailsAlreadyOnFile,
    element: <CompassDetailsAlreadyOnFilePage />,
  },
  { path: ROUTES.compassDisclaimer, element: <DisclaimerPage /> },
  { path: ROUTES.compassHowItWorks, element: <CompassHowItWorksPage /> },
  { path: ROUTES.compassPartners, element: <CompassPartnersPage /> },
  { path: ROUTES.compassSnapshots, element: <CompassSnapshotsPage /> },
  { path: ROUTES.compassSnapshot, element: <CompassSnapshotPage /> },
  { path: ROUTES.compassSharing, element: <CompassSharingPage /> },
  { path: ROUTES.compassStart, element: <CompassStartPage /> },
  { path: ROUTES.compassUpdate, element: <CompassUpdatePage /> },
]

const guestLandingRoutes: AppRoute[] = [
  { path: ROUTES.confirmConsent, element: <ConfirmConsentPage /> },
  { path: ROUTES.inviteUser, element: <InviteUserPage /> },
  { path: ROUTES.requestCallback, element: <RequestCallbackPage /> },
  { path: ROUTES.withdrawGdprConsent, element: <WithdrawGdprConsentPage /> },
  { path: ROUTES.clientPayment, element: <ClientPaymentPage /> },
]

const guestLandingWhiteTemplateRoutes: AppRoute[] = [
  { path: ROUTES.invitation, element: <InvitationPage /> },
  {
    path: ROUTES.commissionConsent,
    element: <ReferralCommissionConsentPage />,
  },
]

const companyUserLandingRoutes: AppRoute[] = [
  { path: ROUTES.suspended, element: <SuspendedPage /> },
  { path: ROUTES.shortlist, element: <ShortlistPage /> },
]

const icaewRoutes: AppRoute[] = [
  { path: ROUTES.icaewDisclaimer, element: <DisclaimerPage /> },
  { path: ROUTES.icaewOverview, element: <IcaewOverviewPage /> },
  { path: ROUTES.icaewFirmSearch, element: <IcaewFirmSearchPage /> },
  { path: ROUTES.icaewTrends, element: <IcaewTrendsPage /> },
]

const miscRoutes: AppRoute[] = [
  { path: ROUTES.compassPdfGuide, element: <CompassPdfGuidePage /> },
  { path: ROUTES.companyCompassInvite, element: <CompanyCompassInvitePage /> },
  {
    path: ROUTES.companyCompassInvitePreview,
    element: <CompanyCompassInvitePreviewPage />,
  },
  {
    path: ROUTES.companyCompass,
    element: (
      <AuthGuard requiredUserType={USER_TYPE.company}>
        <CompanyCompassPage />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.companyCompassView,
    element: (
      <AuthGuard requiredUserType={USER_TYPE.company}>
        <CompanyCompassViewPage />
      </AuthGuard>
    ),
  },
  {
    path: ROUTES.compassPdfReportPreview,
    element: <CompassPdfReportPreviewPage />,
  },
  {
    path: ROUTES.compassPdfReportSection,
    element: <CompassPdfReportSectionPage />,
  },
  { path: ROUTES.compassRedirect, element: <CompassRedirectPage /> },
  {
    path: ROUTES.rqCertifiedNdaPdf,
    element: <RqCertifiedNdaPdfPage />,
  },
  {
    path: ROUTES.userCompass,
    element: (
      <CompassGuard>
        <UserCompassPage />
      </CompassGuard>
    ),
  },
  {
    path: ROUTES.officeTaskPane,
    element: (
      <OfficeLayout>
        <Office365TaskPanePage />
      </OfficeLayout>
    ),
  },
]
const SentryAwareRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const Root: React.FC = () => {
  const location = useLocation()

  React.useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  return (
    <>
      <SentryAwareRoutes>
        <Route element={<AppLayout />}>
          {/* Guest */}
          {guestRoutes.map((route) => (
            <Route path={route.path} element={route.element} key={route.path} />
          ))}

          {/* Account setup */}
          {accountSetupRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <AuthGuard>
                  <LandingGreyLayout variant="card">
                    {route.element}
                  </LandingGreyLayout>
                </AuthGuard>
              }
              key={route.path}
            />
          ))}

          {/* Company dashboard */}
          {companyDashboardRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <AuthGuard requiredUserType={USER_TYPE.company}>
                  <CompanyDashboardLayout>
                    {route.element}
                  </CompanyDashboardLayout>
                </AuthGuard>
              }
              key={route.path}
            />
          ))}

          {/* Client dashboard */}
          {clientDashboardRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <AuthGuard requiredUserType={USER_TYPE.client}>
                  <ClientDashboardLayout>{route.element}</ClientDashboardLayout>
                </AuthGuard>
              }
              key={route.path}
            />
          ))}

          {/* Company user landing routes */}
          {companyUserLandingRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <AuthGuard requiredUserType={USER_TYPE.company}>
                  <LandingBlackLayout>{route.element}</LandingBlackLayout>
                </AuthGuard>
              }
              key={route.path}
            />
          ))}

          {/* ICAEW */}
          {icaewRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <AuthGuard requiredUserType={USER_TYPE.icaew}>
                  <IcaewDashboardLayout>{route.element}</IcaewDashboardLayout>
                </AuthGuard>
              }
              key={route.path}
            />
          ))}

          {/* Guest landing */}
          {guestLandingRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <LandingBlackLayout>
                  <MinimalLayout>{route.element}</MinimalLayout>
                </LandingBlackLayout>
              }
              key={route.path}
            />
          ))}

          {/* Guest landing - white template */}
          {guestLandingWhiteTemplateRoutes.map((route) => (
            <Route
              path={route.path}
              element={<LandingWhiteLayout>{route.element}</LandingWhiteLayout>}
              key={route.path}
            />
          ))}

          {/* Misc */}
          {miscRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <Sentry.ErrorBoundary
                  key={location.pathname}
                  fallback={({ error }) => <ErrorFallback error={error} />}
                >
                  <Suspense fallback={<LoadingWidget />}>
                    {route.element}
                  </Suspense>
                </Sentry.ErrorBoundary>
              }
              key={route.path}
            />
          ))}

          <Route
            path={ROUTES.invalidOrExpired}
            element={<InvalidOrExpiredPage />}
          />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </SentryAwareRoutes>
    </>
  )
}

export default Root
