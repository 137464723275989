import { ReferralTypeOption } from '../../../../types/misc'

export const SelectReferralMethodInputFields: Record<
  keyof SelectReferralMethodInput,
  string
> = {
  referralType: 'referralType',
  userEmailMessage: 'userEmailMessage',
  clientCircumstances: 'clientCircumstances',
  confirmIncidentalReferral: 'confirmIncidentalReferral',
  attachment: 'attachment',
  callbackTimes: 'callbackTimes',
  callbackTelephone: 'callbackTelephone',
  hasConfirmedBookedCall: 'hasConfirmedBookedCall',
}

export interface SelectReferralMethodInput {
  referralType?: ReferralTypeOption
  userEmailMessage?: string
  attachment?: File
  clientCircumstances?: string
  confirmIncidentalReferral?: boolean
  callbackTimes?: string[]
  callbackTelephone?: string
  hasConfirmedBookedCall?: boolean
}
