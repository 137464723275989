import * as yup from 'yup'

import useAppSelector from '../../../../hooks/useAppSelector'
import { REFERRAL_TYPE, REFERRAL_TYPE_OPTION } from '../../../../lib/constants'
import { getCompanyTypeByCode } from '../../../../lib/helpers/companyHelpers'
import {
  arrayToCommaSeparatedString,
  startsWithVowel,
} from '../../../../lib/helpers/helperFunctions'
import {
  selectIsRequestingReferralCallbackForClient,
  selectIsSchedulingMeetingForClient,
  selectReferClientMetadataOrFail,
} from '../../../../redux/slices/referClient'
import {
  CompanyTypeCode,
  NormalReferralTypeOption,
  ReferralType,
  ReferralTypeOption,
} from '../../../../types/misc'
import { ClientItem } from '../../../../types/responses/clients'
import {
  CompanyTypeItem,
  ServiceAreaItem,
} from '../../../../types/responses/common-data'
import { CompassReportCollectionItem } from '../../../../types/responses/compass-reports'
import { ReferralEmailItem } from '../../../../types/responses/referrals'
import { ClientDetailsInput } from '../3_ClientDetails/constants'
import { SelectReferralMethodInputFields } from './constants'

export function useSelectReferralMethodValidationSchema() {
  const metadata = useAppSelector(selectReferClientMetadataOrFail)
  const isRequestingReferralCallbackForClient = useAppSelector(
    selectIsRequestingReferralCallbackForClient,
  )
  const isSchedulingMeetingForClient = useAppSelector(
    selectIsSchedulingMeetingForClient,
  )

  return yup.object().shape({
    [SelectReferralMethodInputFields.confirmIncidentalReferral]: yup
      .boolean()
      .when('_', (_, schema) => {
        if (metadata.shouldConfirmIncidentalReferral) {
          return schema.oneOf(
            [true],
            'You must confirm that this referral is incidental.',
          )
        }

        return schema
      }),

    [SelectReferralMethodInputFields.callbackTelephone]: yup
      .string()
      .when('_', (_, schema) => {
        if (isRequestingReferralCallbackForClient) {
          return schema.required('Please provide a telephone number')
        }

        return schema
      }),

    [SelectReferralMethodInputFields.callbackTimes]: yup
      .array()
      .when('_', (_, schema) => {
        if (isRequestingReferralCallbackForClient) {
          return schema
            .min(1, 'Please select at least one callback time')
            .required('required')
        }

        return schema
      }),

    [SelectReferralMethodInputFields.hasConfirmedBookedCall]: yup
      .boolean()
      .when('_', (_, schema) => {
        if (isSchedulingMeetingForClient) {
          return schema
            .oneOf(
              [true],
              'Please confirm that you have booked a call for the client before proceeding',
            )
            .required()
        }

        return schema
      }),
  })
}

interface UserEmailMessageContext {
  client: ClientItem
  clientDetails: ClientDetailsInput
  referralType: NormalReferralTypeOption
  toCompanyContactNames: ReferralEmailItem['toNames']
  selectedServiceAreas: ServiceAreaItem[]
  compassReport?: CompassReportCollectionItem
  companyTypes: CompanyTypeItem[]
  companyTypeCode: CompanyTypeCode
}

export function buildUserEmailMessage(context: UserEmailMessageContext) {
  const {
    client,
    clientDetails,
    referralType,
    toCompanyContactNames,
    selectedServiceAreas,
    compassReport,
    companyTypes,
    companyTypeCode,
  } = context

  const toCompanyContactName = getToCompanyContactName(toCompanyContactNames)
  const serviceAreaNames = selectedServiceAreas.map(
    (serviceArea) => serviceArea.name,
  )
  const serviceAreaNamesString = arrayToCommaSeparatedString(serviceAreaNames)
  const compassReportMessage = compassReport
    ? ` Please find background information on ${client.firstName}'s personal affairs in the attached snapshot. `
    : ' '

  const readableCompanyType = getCompanyTypeByCode(
    companyTypes,
    companyTypeCode,
  )

  const messages: Record<NormalReferralTypeOption, string[]> = {
    directCc: [
      `Dear ${toCompanyContactName},\n\n`,
      `${client?.firstName} ${client?.lastName} (cc'd) is after some advice on ${serviceAreaNamesString}.${compassReportMessage}Can I leave it with you to please reach out to ${client?.firstName}?`,
      clientDetails?.reason?.length ? `\n\n${clientDetails.reason}` : '',
    ],
    direct: [
      `Dear ${toCompanyContactName},\n\n`,
      `Our client, ${client?.abbreviatedName} is in need of advice regarding ${serviceAreaNamesString}.${compassReportMessage}Are you able to please reach out to them on the below number / email address?`,
      clientDetails?.reason?.length ? `\n\n${clientDetails.reason}` : '',
    ],
    indirect: [
      `Dear ${client?.firstName},\n\n`,
      `You have requested advice concerning ${serviceAreaNamesString}. `,
      `Please see below for details of ${
        startsWithVowel(readableCompanyType.name) ? 'an' : 'a'
      } ${
        readableCompanyType.name
      } that I think will be able to assist. Feel free to schedule a call with them using the below 'Schedule a conversation' link.`,
    ],
  }

  const message = messages[referralType]

  return message.join('')
}

export function getToCompanyContactName(
  toNames: ReferralEmailItem['toNames'],
): string {
  // If the only user is 'RQ Admin', display 'Adviser' instead
  if (toNames.length === 1 && toNames[0] === 'RQ') {
    return 'Adviser'
  }

  // If there are multiple users, always prefer names of other users over
  // the 'RQ Admin' user
  const toNamesSorted = toNames.sort((nameA, nameB) => {
    if (nameA === 'RQ' || nameB === 'RQ') {
      return -1
    }

    return 0
  })

  return toNamesSorted.length > 1
    ? `${toNamesSorted[0]} and team`
    : `${toNamesSorted[0]}`
}

export function normalizeReferralType(
  referralType?: ReferralTypeOption,
): ReferralType | undefined {
  if (!referralType) {
    return undefined
  }

  if (
    referralType === REFERRAL_TYPE_OPTION.directCc ||
    referralType === REFERRAL_TYPE_OPTION.direct ||
    referralType === REFERRAL_TYPE_OPTION.bookCallForClient
  ) {
    return REFERRAL_TYPE.direct
  }

  return referralType as ReferralType
}
