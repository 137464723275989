import {
  CommercialAgreementDirectionInput,
  CommercialAgreementsFormValues,
  CommercialAgreementTypeOption,
  ServiceFeeInput,
} from '../../../redux/slices/commercialAgreementsForm'
import {
  CommercialAgreementDirectionType,
  ObjectMap,
} from '../../../types/misc'

export const COMMERCIAL_AGREEMENT_DIRECTION = {
  fromTo: 'FROM_TO',
  toFrom: 'TO_FROM',
} satisfies Record<string, CommercialAgreementDirectionType>

export const COMMERCIAL_AGREEMENT_DIRECTION_INPUT_FIELDS: ObjectMap<CommercialAgreementDirectionInput> =
  {
    type: 'type',
    serviceFees: 'serviceFees',
    disclosure: 'disclosure',
    existingAgreementFile: 'existingAgreementFile',
  }

export const SERVICE_FEE_INPUT_FIELDS: Record<
  keyof ServiceFeeInput,
  keyof ServiceFeeInput
> = {
  serviceArea: 'serviceArea',
  serviceAreaOther: 'serviceAreaOther',
  feeCategory: 'feeCategory',
  feeCategoryOther: 'feeCategoryOther',
  shareType: 'shareType',
  shareValue: 'shareValue',
  duration: 'duration',
  notes: 'notes',
  example: 'example',
  passBackFee: 'passBackFee',
  passBackFeeNotes: 'passBackFeeNotes',
  isFromCompany: 'isFromCompany',
  willAddAnother: 'willAddAnother',
}

export const COMMERCIAL_AGREEMENT_FIELDS: Record<
  keyof CommercialAgreementsFormValues,
  keyof CommercialAgreementsFormValues
> = {
  hasCommercialAgreement: 'hasCommercialAgreement',
  outgoingAgreement: 'outgoingAgreement',
  incomingAgreement: 'incomingAgreement',
}

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const COMMERCIAL_AGREEMENT_TYPE_OPTION = {
  NO: 'NO',
  YFS: 'YFS',
  YJV: 'YJV',
  RQP: 'RQP',
  LATER: 'LATER',
} satisfies Record<string, CommercialAgreementTypeOption>
