import React from 'react'

import Main from '../../misc/Main'

interface Props {
  children: React.ReactNode
}

const LandingBlackLayout: React.FC<Props> = ({ children }) => (
  <Main className="bg-black pb-3">{children}</Main>
)

export default LandingBlackLayout
