import { parse } from 'date-fns/parse'
import * as yup from 'yup'

import { DATE_FORMATS, MAX_INTEGER_VALUE, MAX_MONEY_VALUE } from '../constants'
import { formatMoney } from './helperFunctions'

export const number = () => yup.number().typeError('Must be a number')

export const positiveNumber = () => number().min(0)

export const validInteger = () =>
  number()
    .min(0)
    .max(MAX_INTEGER_VALUE, () => {
      return `Please enter a value that is no greater than ${formatMoney(
        MAX_INTEGER_VALUE,
      )}`
    })

export const validMoneyValue = () =>
  number()
    .min(0)
    .max(MAX_MONEY_VALUE, () => {
      return `Please enter a value that is no greater than ${formatMoney(
        MAX_MONEY_VALUE,
      )}`
    })

export const dayMonthYear = () =>
  yup
    .date()
    .transform((value, originalValue) =>
      parse(originalValue, DATE_FORMATS.DAY_MONTH_YEAR, new Date()),
    )
    .typeError('Must be a valid date')

export const percentage = () => yup.number().min(0).max(100)

export const yupSchemas = {
  number,
  dayMonthYear,
  percentage,
  positiveNumber,
  validMoneyAmount: validMoneyValue,
}
