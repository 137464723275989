import { UpdateCompanyConsentRequest } from '../../types/requests/company-consents'
import { CompanyConsentCollection } from '../../types/responses/companies'
import apiService from './apiService'

class CompanyConsentService {
  async getCompanyConsents(
    currentCompanyId: number,
  ): Promise<CompanyConsentCollection> {
    const response = await apiService.get(
      `/v1/companies/${currentCompanyId}/consents`,
    )

    return response.data
  }

  async updateCompanyConsent(
    companyId: number,
    request: UpdateCompanyConsentRequest,
  ) {
    const response = await apiService.post('/v1/company-consents', request)

    return response.data
  }
}

const companyConsentService = new CompanyConsentService()

export default companyConsentService
