import React from 'react'

import { COMPANY_TYPE_CODE } from '../../../lib/constants'
import { CompanyTypeCode } from '../../../types/misc'
import SvgImage, { SvgImageProps } from '../SvgImage'
import accountantDarkIcon from './icons/accountant--dark.svg'
import accountantLightIcon from './icons/accountant--light.svg'
import corporateFinanceDarkIcon from './icons/corporate_finance--dark.svg'
import corporateFinanceLightIcon from './icons/corporate_finance--light.svg'
import defaultIconDark from './icons/default--dark.svg'
import defaultIconLight from './icons/default--light.svg'
import estatePlannerLightIcon from './icons/estate_planner--dark.svg'
import estatePlannerDarkIcon from './icons/estate_planner--light.svg'
import ifaDarkIcon from './icons/ifa--dark.svg'
import ifaLightIcon from './icons/ifa--light.svg'
import insuranceAdviserDarkIcon from './icons/insurance_adviser--dark.svg'
import insuranceAdviserLightIcon from './icons/insurance_adviser--light.svg'
import investmentManagementDarkIcon from './icons/investment_management--dark.svg'
import investmentManagementLightIcon from './icons/investment_management--light.svg'
import mortgageAdviserDarkIcon from './icons/mortgage_adviser--dark.svg'
import mortgageAdviserLightIcon from './icons/mortgage_adviser--light.svg'
import solicitorDarkIcon from './icons/solicitor--dark.svg'
import solicitorLightIcon from './icons/solicitor--light.svg'

type Props = SvgImageProps & {
  companyTypeCode: CompanyTypeCode
  mode?: 'light' | 'dark'
}

const CompanyTypeIcon: React.FC<Props> = ({
  companyTypeCode,
  mode = 'light',
  ...props
}) => {
  const icons: { [Key in CompanyTypeCode]?: [string, string] } = {
    [COMPANY_TYPE_CODE.accountant]: [accountantLightIcon, accountantDarkIcon],
    [COMPANY_TYPE_CODE.corporate_finance]: [
      corporateFinanceLightIcon,
      corporateFinanceDarkIcon,
    ],
    [COMPANY_TYPE_CODE.estate_planner]: [
      estatePlannerLightIcon,
      estatePlannerDarkIcon,
    ],
    [COMPANY_TYPE_CODE.ifa]: [ifaLightIcon, ifaDarkIcon],
    [COMPANY_TYPE_CODE.insurance_adviser]: [
      insuranceAdviserLightIcon,
      insuranceAdviserDarkIcon,
    ],
    [COMPANY_TYPE_CODE.investment_management]: [
      investmentManagementLightIcon,
      investmentManagementDarkIcon,
    ],
    [COMPANY_TYPE_CODE.mortgage_adviser]: [
      mortgageAdviserLightIcon,
      mortgageAdviserDarkIcon,
    ],
    [COMPANY_TYPE_CODE.solicitor]: [solicitorLightIcon, solicitorDarkIcon],
  }

  const icon = icons[companyTypeCode] ?? [defaultIconLight, defaultIconDark]

  return <SvgImage {...props} src={mode === 'light' ? icon[0] : icon[1]} />
}

export default CompanyTypeIcon
