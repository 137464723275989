import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import invariant from 'tiny-invariant'

import { UserType } from '../../types/misc'
import {
  SessionMetadataItem,
  SessionMetadataUser,
} from '../../types/responses/session-metadata'
import { getApiUrl } from '../helpers/apiHelpers'
import { isDevelopment } from '../helpers/helperFunctions'
import errorTypeService from './errorTypeService'

class SentryService {
  init() {
    const apiUrl = getApiUrl()
    invariant(apiUrl, 'Failed to determine API URL')

    Sentry.init({
      enabled: !isDevelopment(), // Disable in development
      normalizeDepth: 7,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,

      // Only attach Sentry headers to outgoing XHR requests to the API
      // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
      tracePropagationTargets: [apiUrl],

      ignoreErrors: [
        // This error surfaces in Safari and appears to come from Posthog.
        // We need to investigate further but as it doesn't seem to affect the
        // user experience, let's filter it out to avoid depleting our quota.
        "undefined is not an object (evaluating 'o[Symbol.iterator]')",
      ],
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        new posthog.SentryIntegration(
          posthog,
          'rq-00',
          6549839,
          'https://eu.posthog.com',
        ),
      ],
      tracesSampleRate: 0.2,
    })
  }

  captureXhrError(error: unknown) {
    Sentry.setExtra('error', error)

    if (errorTypeService.isAxiosError(error)) {
      Sentry.setExtra('error.response', error.response)
    }

    if (error instanceof Error) {
      // Sentry's captureException() method requires an instance of Error
      // otherwise it'll throw an error.
      Sentry.captureException(error)
      return
    }

    sentryService.captureMessageWithId({
      messageId: 'xhr-error',
      message: 'XHR error',
      extra: { error },
    })
  }

  setAppContext(context: SentryAppContext) {
    const { currentUser, currentCompany } = context

    if (!currentUser || !currentCompany) {
      return
    }

    const appContext = {
      currentUser: {
        id: currentUser.id,
        displayName: currentUser.fullName,
        email: currentUser.email,
        isImpersonated: currentUser.impersonated,
      },
      currentCompany: {
        iri: currentCompany?.['@id'],
        name: currentCompany?.name,
      },
    }

    Sentry.setContext('App Context', appContext)
  }

  captureMessageWithId(options: {
    message: string
    messageId: string
    extra?: SentryExtraContext
  }) {
    const { message, messageId, extra } = options

    Sentry.withScope((scope) => {
      if (extra) {
        scope.setExtra('extra', extra)
      }

      Sentry.captureMessage(message, {
        tags: { 'app.messageId': messageId },
        extra,
      })
    })
  }
}

export type SentryExtraContext = Record<string, unknown>

export interface SentryAppContext {
  currentUser: SessionMetadataUser
  currentCompany?: SessionMetadataItem['currentCompany']
  currentUserType: UserType
}

const sentryService = new SentryService()

export default sentryService
