import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

import { isDevelopment } from '../../../../lib/helpers/helperFunctions'
import ErrorMessage from '../../../misc/ErrorMessage'
import Dump from '../../../utils/Dump'

interface Props {
  error: Error
}

const ErrorFallback: React.FC<Props> = ({ error }) => (
  <Container className="mt-4">
    <Row>
      <Col lg={6} className="offset-lg-3">
        <Card>
          <Card.Body className="border">
            {isDevelopment() && (
              <>
                <Dump title="Error message" value={error?.message} expanded />
                <Dump title="Error" value={error} expanded />
              </>
            )}
            <ErrorMessage />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
)

export default ErrorFallback
