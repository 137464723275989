import React from 'react'

import useSettingsState from '../hooks/useSettingsState'
import { THEME } from '../lib/constants'

const initialState = {
  theme: THEME.LIGHT,
}

const ThemeContext = React.createContext(initialState)

function ThemeProvider({ children }) {
  const [theme] = useSettingsState('theme', THEME.LIGHT)

  return (
    <ThemeContext.Provider value={{ theme }}>{children}</ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
