import axios, { InternalAxiosRequestConfig } from 'axios'

import { getApiUrl } from '../helpers/apiHelpers'
import authService from './authService'
import errorTypeService from './errorTypeService'
import sentryService from './sentryService'

const apiService = axios.create({
  baseURL: getApiUrl(),
})

function setRefererPathHeader(
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  if (!config.headers) {
    return config
  }

  config.headers['X-Referer-Path'] = (
    window.location.pathname + window.location.search
  ).substr(1)

  return config
}

function handleErrors(error: unknown) {
  if (errorTypeService.isTokenError(error)) {
    authService.logout()
    return
  }

  if (errorTypeService.isExpiredContextError(error)) {
    // Reload the page to refresh the user context
    window.location.reload()
    return
  }

  sentryService.captureXhrError(error)

  // Don't handle any other error types
  return Promise.reject(error)
}

apiService.interceptors.request.use(function (config) {
  return authService.setAuthHeader(config)
})
apiService.interceptors.request.use(setRefererPathHeader)
apiService.interceptors.response.use((response) => response, handleErrors)

export default apiService
